import React from 'react';
import { Button, Space, Table, Tag, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import qs from 'qs';
import { useNavigate } from 'react-router';

import { useQuery } from '@tanstack/react-query';

import { useAccountStore, useSettingStore } from '../../store';
import axios from '../../utilities/axios';
import { FORMAT, TRADES } from '../../utilities/functions';

import './styles.scss';

const { Title, Text } = Typography;

export default function TradesPage() {

  const { filter } = useSettingStore((state) => state);
  // const { getUser } = useUserState();
  // const user = getUser();
  // const { viewAs } = useManagementStore((state) => state);
  const navigate = useNavigate();
  const { allAccounts } = useAccountStore((state) => state);

  // const isViewAsActive = JSON.stringify(viewAs?.newUser?.token || null) !== JSON.stringify(viewAs?.mainUser?.token || null);

  // const fetchAccounts = () => axios.get('crud/account', {
  //   headers: {
  //     token: isViewAsActive ? viewAs.newUser.token : user?.token
  //   }
  // }).then(({ data }) => data.map((a) => FORMAT.ACCOUNTS_BEAUTIFY(a)));

  // useQuery(['accounts', viewAs], () => fetchAccounts().then(async (accounts) => {

  //   const results = await LOGS.processLogs(accounts, setAllAccounts);

  //   return results;

  // }), { enabled: user.token !== undefined });

  const { isLoading, data: trades, refetch } = useQuery(['trades', filter], () => TRADES.fetchTrades(filter, allAccounts));

  const closeOptionTradeByID = (tradeObj) => axios.post('trades/close-option-trade', {
    data: {
      trade: tradeObj
    },
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' })
  }).then(() => refetch());

  const viewTrade = (e) => navigate(e._id);

  const closeOptionTrade = (e) => closeOptionTradeByID(e);

  const sorter = (a, b) => {

    if (typeof a === 'string' && typeof b === 'string') {

      return a.localeCompare(b);

    } if (Number.isNaN(a) || Number.isNaN(b)) {

      return (a || '').localeCompare(b || '');

    }
    return a - b;

  };

  const columns = [
    {
      title: 'Status',
      dataIndex: '',
      key: '',
      render: (e, i) => {

        let color;
        if (e.status === 'open') {

          color = 'blue';

        } else if (e.status === 'closed') {

          color = '';

        } else {

          color = '#E57373';

        }
        return (
          e.status === 'open' && e.assetType === 'Option' && dayjs() > dayjs(e.optionContract.date) ?
              <>
                  <Tag color={color} key={`${e.status}_${i}`}>
                      {e.status.toUpperCase()}
                  </Tag>
                  <Tooltip title='Close'>
                      <Button size='small' type='primary' onClick={() => closeOptionTrade(e)}>Close/Expired</Button>
                  </Tooltip>
              </>

            : <Tag color={color} key={`${e.status}_${i}`}>
                {e.status.toUpperCase()}
            </Tag>

        );

      }
    },
    {
      title: 'Asset Type',
      dataIndex: 'assetType',
      key: 'assetType'
    },
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol',
      sorter: (a, b) => sorter(a.symbol, b.symbol)
    },
    {
      title: 'Side',
      dataIndex: 'side',
      key: 'side'
    },
    {
      title: 'Open Time',
      dataIndex: 'openTime',
      key: 'openTime',
      sorter: (a, b) => sorter(a.openTime, b.openTime)
    },
    {
      title: 'Close Time',
      dataIndex: 'closeTime',
      key: 'closeTime',
      sorter: (a, b) => sorter(a.closeTime, b.closeTime)
    },
    {
      title: 'Open Price',
      dataIndex: 'openPrice',
      key: 'openPrice',
      sorter: (a, b) => sorter(FORMAT.CURRENCY_TO_NUMBER(a.openPrice), FORMAT.CURRENCY_TO_NUMBER(b.openPrice))
    },
    {
      title: 'Close Price',
      dataIndex: 'closePrice',
      key: 'closePrice',
      sorter: (a, b) => sorter(FORMAT.CURRENCY_TO_NUMBER(a.closePrice), FORMAT.CURRENCY_TO_NUMBER(b.closePrice))

    },
    {
      title: 'Total Volume',
      dataIndex: 'totalVolume',
      key: 'totalVolume',
      sorter: (a, b) => sorter(a.totalVolume, b.totalVolume)
    },
    {
      title: 'Total Commission',
      dataIndex: 'totalCommission',
      key: 'totalCommission',
      sorter: (a, b) => sorter(FORMAT.CURRENCY_TO_NUMBER(a.totalCommission), FORMAT.CURRENCY_TO_NUMBER(b.totalCommission))
    },
    {
      title: 'Profit and Loss (P&L)',
      dataIndex: 'netPnL',
      key: 'netPnL',
      sorter: (a, b) => sorter(FORMAT.CURRENCY_TO_NUMBER(a.netPnL), FORMAT.CURRENCY_TO_NUMBER(b.netPnL)),
      render: (netPnL) => {

        let color;
        const value = FORMAT.CURRENCY_TO_NUMBER(netPnL);
        if (value > 0) {

          color = '#29a373';

        } else if (value < 0) {

          color = '#E57373';

        } else {

          color = 'grey';

        }
        return (
            <Tag color={color} key={netPnL}>
                {netPnL}
            </Tag>
        );

      }
    },
    {
      title: '# Executions',
      dataIndex: 'executionsNumber',
      key: 'executionsNumber',
      sorter: (a, b) => sorter(a.executionsNumber, b.executionsNumber)
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      render: (_, { tags }) => (
          <>
              { tags.map((tag) => (
                  <Tag color={tag.color} key={tag.label}>
                      {tag.label}
                  </Tag>
              ))
            }
          </>
      )
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
      render: (_, { notes }) => (
          <>
              { notes && notes.length > 0 ?
                  <span>
                      {notes.length}
                  </span>

                :
                  <span>0</span>
        }
          </>
      )
    }
  ];

  const columnsExpanded = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol'
    },
    {
      title: 'Side',
      dataIndex: 'side',
      key: 'side'
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price'
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity'
    },
    {
      title: 'Commission',
      dataIndex: 'commission',
      key: 'commission'
    },
    {
      title: 'Gross Realized P&L',
      dataIndex: 'realizedGain',
      key: 'realizedGain',
      render: (PnL) => {

        let color;
        const value = FORMAT.CURRENCY_TO_NUMBER(PnL);
        if (value > 0) {

          color = '#29a373';

        } else if (value < 0) {

          color = '#E57373';

        } else {

          color = 'grey';

        }
        return (
            <Tag color={color} key={PnL}>
                {PnL}
            </Tag>
        );

      }
    },
    {
      title: 'Net Realized P&L',
      dataIndex: 'netRealizedGain',
      key: 'netRealizedGain',
      render: (PnL) => {

        let color;
        const value = FORMAT.CURRENCY_TO_NUMBER(PnL);
        if (value > 0) {

          color = '#29a373';

        } else if (value < 0) {

          color = '#E57373';

        } else {

          color = 'grey';

        }
        return (
            <Tag color={color} key={PnL}>
                {PnL}
            </Tag>
        );

      }
    },
    {
      title: 'Open Position',
      dataIndex: 'remainingShares',
      key: 'remainingShares'
    }
  ];

  if (isLoading) return <></>;

  if (!trades) {

    return (
        <Space direction='vertical' className='no-data' wrap>
            <Title>No data available.</Title>
            <Text>Select different filters or upload trade logs by using the <strong>Import Trades</strong> button.</Text>
        </Space>
    );

  }
  return (
      <Space className='stretch-space' direction='vertical' wrap>
          <Table
              rowKey='key'
              onRow={(record) => ({
                onClick: () => viewTrade(record)
              })}
              columns={columns}
              pagination={{
                hideOnSinglePage: true,
                size: 'default',
                position: ['bottomCenter'],
                showTitle: false,
                responsive: true
              }}
              className='trades-table'
              expandable={{
                expandedRowRender: (record) => (
                    <Table
                        rowKey={(r) => r.date + r.remainingShares} // trying unique key
                        columns={columnsExpanded}
                        dataSource={record.executions}
                        pagination={false}
              />
                ),
                rowExpandable: (record) => record.symbol !== 'Not Expandable'
              }}
              dataSource={trades}
        />
      </Space>
  );

}

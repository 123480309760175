import React, { useState } from 'react';
import { Button, Radio } from 'antd';

import CategoryComponent from './components/category';
import TagComponent from './components/tag';

import './styles.scss';

export default function TagManagementPage() {

  const [selectedComponent, setSelectedComponent] = useState('CategoryComponent');
  const [openControllModal, setOpenControllModal] = useState(false);

  return (
      <div className='tagManagement'>
          <h2>Tags Management</h2>
          <div className='controller'>
              <Radio.Group
                  onChange={(e) => {

                    setSelectedComponent(e.target.value);
                    setOpenControllModal(false);

                  }}
                  value={selectedComponent} >
                  <Radio.Button value='CategoryComponent'>Category</Radio.Button>
                  <Radio.Button value='TagComponent'>Tag</Radio.Button>
              </Radio.Group>

              <Button
                  icon={<span className='tt-plus' />}
                  type='primary'
                  onClick={() => {

                    setOpenControllModal(true);

                  }}
                  >
                  {selectedComponent === 'CategoryComponent' ? 'Category' : 'Tag' }
              </Button>
          </div>

          {selectedComponent === 'CategoryComponent' && <CategoryComponent setOpenControllModal={setOpenControllModal} openControllModal={openControllModal} />}
          {selectedComponent === 'TagComponent' && <TagComponent setOpenControllModal={setOpenControllModal} openControllModal={openControllModal} />}
      </div>
  );

}

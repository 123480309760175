import React, { useState } from 'react';
import { Button, ColorPicker, Dropdown, Input, Menu, message, Modal, Table, Typography } from 'antd';
import PropTypes from 'prop-types';

import { DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import showDeleteConfirm from '../../../../components/Confirmation-modal';
import { useSettingStore, useUserState } from '../../../../store';
import axios from '../../../../utilities/axios';

const fetchCategoryTags = (token) => axios.get('crud/tagCategory', {
  headers: {
    token
  }
}).then(({ data }) => data);

export default function CategoryComponent({ setOpenControllModal, openControllModal }) {

  const { getUser } = useUserState();
  const user = getUser();

  const { filter, changeFilter } = useSettingStore((state) => state);

  const { Text } = Typography;
  const [categorySelected, setCategorySelected] = useState({});

  const [submited, setSubmited] = useState(false);
  const defaultCategoryNames = [
    'confirmation',
    'indicators',
    'profit set',
    'strategy set',
    'trade category'];

  const queryClient = useQueryClient();

  const { data: tagCategories = [], isLoading } = useQuery(['tradeTagCategory'], () => fetchCategoryTags(user?.token));

  const closeModal = () => {

    setSubmited(false);
    setOpenControllModal(false);
    setCategorySelected({});

  };

  const getColorValue = (color) => {

    if (color && color.metaColor) {

      return color.toHexString();

    } if (typeof color === 'string') {

      return color;

    }
    return '#F4AA41';

  };

  const tagCreateorAddorUpdate = () => {

    if (defaultCategoryNames.filter((x) => x === categorySelected.label.trim().toLowerCase()).length > 0) {

      message.error('The category already exists.');
      return;

    }

    setSubmited(true);

    const { _id: categoryId, label, color } = categorySelected;

    if (label && label.trim().length > 0) {

      const url = !categoryId ? 'crud/tagCategory' : 'crud/tagCategoryUpdate';

      const body = {
        categoryId,
        label: label.trim(),
        color: getColorValue(color)
      };

      axios.post(url, body, {
        headers: {
          token: user?.token
        }
      }).then(() => {

        message.success(!categoryId ? 'New category created' : 'Category updated');
        queryClient.invalidateQueries(['trade-individual']);
        queryClient.invalidateQueries(['tags']);
        queryClient.invalidateQueries(['tradeTagCategory']);
        closeModal();

      }).catch((error) => {

        message.error(error.response?.data?.message || 'Oops! Something Went Wrong. Please try again.');

      }).finally(() => {

        setSubmited(false);

      });

    }

  };

  const tagCreateorDelete = (isConfirmed, id) => {

    if (isConfirmed) {

      const tagsSelected = tagCategories.filter((x) => x._id === id)[0].tags.map((x) => x._id);

      axios.delete(`crud/tagCategory/${id}`, {
        headers: {
          token: user?.token
        }
      }).then(() => {

        message.success('Category deleted');
        queryClient.invalidateQueries(['trade-individual']);
        queryClient.invalidateQueries(['tags']);
        queryClient.invalidateQueries(['tradeTagCategory']);

        changeFilter('tags', filter.tags.filter((x) => !tagsSelected.includes(x)));

      }).catch((error) => {

        message.error(error.response?.data?.message || 'Oops! Something Went Wrong. Please try again.');

      });

    }

  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'label',
      key: 'label',
      width: 150,
      render: (label) => (
          <strong>{label}</strong>
      )
    },
    {
      title: 'Color',
      dataIndex: 'color',
      key: 'color',
      render: (color) => (
          <span className='categoryColor' style={{ backgroundColor: color }}></span>
      )
    },
    {
      title: 'Action',
      key: 'operation',
      fixed: 'right',
      width: 100,
      render: (record) => (
          <Dropdown
              trigger='click'
              disabled={!(record.userId !== null)}
              overlay={
                  <Menu>
                      <Menu.Item
                          key='1'
                          onClick={() => {

                            setOpenControllModal(true);
                            setCategorySelected(record);

                          }}
              >
                          <Text>
                              <i className='tt-edit' /> Edit
                          </Text>
                      </Menu.Item>
                      <Menu.Item
                          key='2'
                          onClick={() => {

                            showDeleteConfirm(
                              (isConfirmed) => tagCreateorDelete(isConfirmed, record._id),
                              record.label,
                              record.tags <= 0 ?
                                `Are you sure to delete "${record.label}" category?` :
                                // eslint-disable-next-line
                                'This category is currently assigned to one or more trades. Deleting it will remove the category and tag from all associated trades. Are you sure you want to proceed?'
                            );

                          }}
              >
                          <Text type='danger'>
                              <DeleteOutlined /> Delete...
                          </Text>
                      </Menu.Item>
                  </Menu>
          }
              placement='bottomLeft'
              arrow
        >
              <Button type='text' icon={<MoreOutlined />} />
          </Dropdown>
      )
    }
  ];

  return (
      <div className='categoryIndivisualManagement'>
          {isLoading === false && (
          <Table
              columns={columns}
              dataSource={tagCategories}
              pagination={false}
              rowKey='key'
        />
          )}

          <Modal
              className='tagModal borderModal'
              title={!categorySelected._id ? 'New Category' : 'Edit Category'}
              open={openControllModal}
              onCancel={closeModal}
              footer={
                  <>
                      <Button onClick={closeModal}>Cancel</Button>
                      <Button type='primary' onClick={tagCreateorAddorUpdate}>
                          {categorySelected._id ? 'Save' : 'Create'}
                      </Button>
                  </>
        }
      >
              <div className='controllerItem'>
                  <label>Category Name</label>
                  <Input
                      className={submited === true && (!categorySelected.label || categorySelected.label.trim() === '') ? 'ant-input-status-error' : ''}
                      value={categorySelected.label || ''}
                      onChange={(e) => {

                        setCategorySelected({
                          ...categorySelected,
                          label: e.target.value
                        });

                      }}
                      placeholder='Name your Category'
          />
                  {submited === true && (!categorySelected.label || categorySelected.label.trim() === '') &&
                  <div>
                      <div id='username_help' className=' ant-form-item-explain-connected' role='alert'>
                          <div style={{ color: 'red' }} className='ant-form-item-explain-error'>Please enter label</div>
                      </div>
                  </div>
          }
              </div>

              <div className='controllerItem'>
                  <label>Color</label>

              </div>
              <ColorPicker
                  format='hex'
                  value={categorySelected.color || '#F4AA41'}
                  onChange={(color) => {

                    setCategorySelected({
                      ...categorySelected,
                      color
                    });

                  }}
        />
          </Modal>
      </div>
  );

}

CategoryComponent.propTypes = {
  openControllModal: PropTypes.bool,
  setOpenControllModal: PropTypes.func
};

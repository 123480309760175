import React from 'react';
import { Card, Col, Row, Space, Statistic, Typography } from 'antd';
import PropTypes from 'prop-types';

import DailyPnLBarChart from '../../../../components/visualizations/daily-pnl-bar-chart';
import RunningPnLLineChart from '../../../../components/visualizations/running-pnl-chart';
import { statisticsPnL } from '../../../../utilities/descriptions';
import { FORMAT } from '../../../../utilities/functions';
import DescriptionIcon from '../../../dashboard/components/description-icon';

const { Title, Text } = Typography;

const PnLStatistics = ({
  netPnL,
  avgPnL,
  avgPnLPerDay,
  avgPnLPerMonth,
  avgPnLPerYear,
  maxPnL,
  minPnL,
  maxPnLPerDay,
  minPnLPerDay,
  maxPnLPerMonth,
  minPnLPerMonth,
  maxPnLPerYear,
  minPnLPerYear,
  dailyData,
  PnLPerDayAccumulated
}) => {

  const redColor = '#E57373';
  const greenColor = '#29a373';

  // TODO: Remove the following and add conditional type to each antd typography element
  const netPnLColor = FORMAT.CURRENCY_TO_NUMBER(netPnL) > 0 ? { color: greenColor } : { color: redColor };
  const avgPnLColor = FORMAT.CURRENCY_TO_NUMBER(avgPnL) > 0 ? { color: greenColor } : { color: redColor };
  const avgPnLPerDayColor = FORMAT.CURRENCY_TO_NUMBER(avgPnLPerDay) > 0 ? { color: greenColor } : { color: redColor };
  const avgPnLPerMonthColor = FORMAT.CURRENCY_TO_NUMBER(avgPnLPerMonth) > 0 ? { color: greenColor } : { color: redColor };
  const avgPnLPerYearColor = FORMAT.CURRENCY_TO_NUMBER(avgPnLPerYear) > 0 ? { color: greenColor } : { color: redColor };
  const minPnLColor = FORMAT.CURRENCY_TO_NUMBER(minPnL) > 0 ? { color: greenColor } : { color: redColor };
  const maxPnLColor = FORMAT.CURRENCY_TO_NUMBER(maxPnL) > 0 ? { color: greenColor } : { color: redColor };
  const minPnLPerDayColor = FORMAT.CURRENCY_TO_NUMBER(minPnLPerDay) > 0 ? { color: greenColor } : { color: redColor };
  const maxPnLPerDayColor = FORMAT.CURRENCY_TO_NUMBER(maxPnLPerDay) > 0 ? { color: greenColor } : { color: redColor };
  const minPnLPerMonthColor = FORMAT.CURRENCY_TO_NUMBER(minPnLPerDay) > 0 ? { color: greenColor } : { color: redColor };
  const maxPnLPerMonthColor = FORMAT.CURRENCY_TO_NUMBER(maxPnLPerDay) > 0 ? { color: greenColor } : { color: redColor };
  const minPnLPerYearColor = FORMAT.CURRENCY_TO_NUMBER(minPnLPerDay) > 0 ? { color: greenColor } : { color: redColor };
  const maxPnLPerYearColor = FORMAT.CURRENCY_TO_NUMBER(maxPnLPerDay) > 0 ? { color: greenColor } : { color: redColor };
  return (
      <Space direction='vertical'>
          <Row gutter={[24, 24]} style={{ width: '100%' }}>
              <Col span={8}>
                  <Card>
                      <Title level={3}>P&L <Text type='secondary'>Total</Text>&nbsp;&nbsp;<DescriptionIcon description={statisticsPnL.pnlTotal} /></Title>
                      <Statistic
                          title='Net Profit & Loss'
                          value={netPnL}
                          precision={2}
                          valueStyle={netPnLColor} />
                  </Card>
              </Col>
              <Col span={8}>
                  <Card>
                      <Title level={3}>Average P&L <Text type='secondary'>/ Trade</Text>&nbsp;&nbsp;<DescriptionIcon description={statisticsPnL.averagePnLPerTrade} /></Title>
                      <Statistic
                          title='Average Net Profit & Loss'
                          value={avgPnL}
                          precision={2}
                          valueStyle={avgPnLColor} />
                  </Card>
              </Col>
              <Col span={8}>
                  <Card>
                      <Title level={3}>Average P&L <Text type='secondary'>/ Day</Text>&nbsp;&nbsp;<DescriptionIcon description={statisticsPnL.averagePnLPerDay} /></Title>
                      <Statistic
                          title='Average Net Profit & Loss'
                          value={avgPnLPerDay}
                          precision={2}
                          valueStyle={avgPnLPerDayColor} />
                  </Card>
              </Col>
              <Col span={8}>
                  <Card>
                      <Title level={3}>Average P&L <Text type='secondary'>/ Month</Text>&nbsp;&nbsp;<DescriptionIcon description={statisticsPnL.averagePnLPerMonth} /></Title>
                      <Statistic
                          title='Average Net Profit & Loss'
                          value={avgPnLPerMonth}
                          precision={2}
                          valueStyle={avgPnLPerMonthColor} />
                  </Card>
              </Col>
              <Col span={8}>
                  <Card>
                      <Title level={3}>Average P&L <Text type='secondary'>/ Year</Text>&nbsp;&nbsp;<DescriptionIcon description={statisticsPnL.averagePnLPerYear} /></Title>
                      <Statistic
                          title='Average Net Profit & Loss'
                          value={avgPnLPerYear}
                          precision={2}
                          valueStyle={avgPnLPerYearColor} />
                  </Card>
              </Col>
              <Col span={8}>
                  <Card>
                      <Title level={3}>Max P&L <Text type='secondary'>/ Trade</Text>&nbsp;&nbsp;<DescriptionIcon description={statisticsPnL.maxPnLPerTrade} /></Title>
                      <Statistic
                          title='Highest Net Profit & Loss'
                          value={maxPnL}
                          precision={2}
                          valueStyle={maxPnLColor} />
                  </Card>
              </Col>
              <Col span={8}>
                  <Card>
                      <Title level={3}>Min P&L <Text type='secondary'>/ Trade</Text>&nbsp;&nbsp;<DescriptionIcon description={statisticsPnL.minPnLPerTrade} /></Title>
                      <Statistic
                          title='Lowest Net Profit & Loss'
                          value={minPnL}
                          precision={2}
                          valueStyle={minPnLColor} />
                  </Card>
              </Col>
              <Col span={8}>
                  <Card>
                      <Title level={3}>Max P&L <Text type='secondary'>/ Day</Text>&nbsp;&nbsp;<DescriptionIcon description={statisticsPnL.maxPnLPerDay} /></Title>
                      <Statistic
                          title='Highest Net Profit & Loss'
                          value={maxPnLPerDay}
                          precision={2}
                          valueStyle={maxPnLPerDayColor} />
                  </Card>
              </Col>
              <Col span={8}>
                  <Card>
                      <Title level={3}>Min P&L <Text type='secondary'>/ Day</Text>&nbsp;&nbsp;<DescriptionIcon description={statisticsPnL.minPnLPerDay} /></Title>
                      <Statistic
                          title='Lowest Net Profit & Loss'
                          value={minPnLPerDay}
                          precision={2}
                          valueStyle={minPnLPerDayColor} />
                  </Card>
              </Col>
              <Col span={8}>
                  <Card>
                      <Title level={3}>Max P&L <Text type='secondary'>/ Month</Text>&nbsp;&nbsp;<DescriptionIcon description={statisticsPnL.maxPnLPerMonth} /></Title>
                      <Statistic
                          title='Highest Net Profit & Loss'
                          value={maxPnLPerMonth}
                          precision={2}
                          valueStyle={maxPnLPerMonthColor} />
                  </Card>
              </Col>
              <Col span={8}>
                  <Card>
                      <Title level={3}>Min P&L <Text type='secondary'>/ Month</Text>&nbsp;&nbsp;<DescriptionIcon description={statisticsPnL.minPnLPerMonth} /></Title>
                      <Statistic
                          title='Lowest Net Profit & Loss'
                          value={minPnLPerMonth}
                          precision={2}
                          valueStyle={minPnLPerMonthColor} />
                  </Card>
              </Col>
              <Col span={8}>
                  <Card>
                      <Title level={3}>Max P&L <Text type='secondary'>/ Year</Text>&nbsp;&nbsp;<DescriptionIcon description={statisticsPnL.maxPnLPerYear} /></Title>
                      <Statistic
                          title='Highest Net Profit & Loss'
                          value={maxPnLPerYear}
                          precision={2}
                          valueStyle={maxPnLPerYearColor} />
                  </Card>
              </Col>
              <Col span={8}>
                  <Card>
                      <Title level={3}>Min P&L <Text type='secondary'>/ Year</Text>&nbsp;&nbsp;<DescriptionIcon description={statisticsPnL.minPnLPerYear} /></Title>
                      <Statistic
                          title='Lowest Net Profit & Loss'
                          value={minPnLPerYear}
                          precision={2}
                          valueStyle={minPnLPerYearColor} />
                  </Card>
              </Col>
          </Row>
          <Row gutter={[24, 24]} style={{ width: '100%' }}>
              <Col span={12}>
                  <Card title={<Title level={3}>Daily P&L <DescriptionIcon description={statisticsPnL.dailyPnl} /></Title>}>
                      <div style={{ height: '20vh' }}>
                          {dailyData && <DailyPnLBarChart label='Daily P&L' data={dailyData} />}
                      </div>
                  </Card>
              </Col>
              <Col span={12}>
                  <Card title={<Title level={3}>Net P&L <DescriptionIcon description={statisticsPnL.netPnL} /></Title>}>
                      <div style={{ height: '20vh' }}>
                          {PnLPerDayAccumulated && <RunningPnLLineChart data={PnLPerDayAccumulated} />}
                      </div>
                  </Card>
              </Col>
          </Row>
      </Space>
  );

};

PnLStatistics.propTypes = {

  netPnL: PropTypes.string,
  avgPnL: PropTypes.string,
  avgPnLPerDay: PropTypes.string,
  avgPnLPerMonth: PropTypes.string,
  avgPnLPerYear: PropTypes.string,
  maxPnL: PropTypes.string,
  minPnL: PropTypes.string,
  maxPnLPerDay: PropTypes.string,
  minPnLPerDay: PropTypes.string,
  maxPnLPerMonth: PropTypes.string,
  minPnLPerMonth: PropTypes.string,
  maxPnLPerYear: PropTypes.string,
  minPnLPerYear: PropTypes.string,
  dailyData: PropTypes.array,
  PnLPerDayAccumulated: PropTypes.array

};

export default PnLStatistics;

import React from 'react';
import { Card, Col, Row, Statistic, Typography } from 'antd';
import PropTypes from 'prop-types';

import DailyVolumeBarChart from '../../../../components/visualizations/daily-volume-bar-chart';
import { statisticsVolume } from '../../../../utilities/descriptions';
import DescriptionIcon from '../../../dashboard/components/description-icon';

const { Title } = Typography;

const VolumeStatistics = ({
  totalVolume,
  totalVolumeOpen,
  totalVolumeClosed,
  totalVolumeWin,
  totalVolumeLoss,
  averageVolume,
  averageVolumeWin,
  averageVolumeLoss,
  averageVolumePerDay,
  averageVolumeWinPerDay,
  averageVolumeLossPerDay,
  averageVolumePerMonth,
  averageVolumeWinPerMonth,
  averageVolumeLossPerMonth,
  averageVolumePerYear,
  averageVolumeWinPerYear,
  averageVolumeLossPerYear,
  maxVolume,
  minVolume,
  dailyData
}) => (

    <Row gutter={[10, 10]} style={{ width: '100%' }}>
        <Col span={8}>
            <Card>
                <Title level={3}>Total Volume</Title>
                <Statistic
                    value={totalVolume}
                />
            </Card>
        </Col>
        <Col span={8}>
            <Card>
                <Title level={3}>Total Closed Volume</Title>
                <Statistic
                    value={totalVolumeClosed}
                />
            </Card>
        </Col>
        <Col span={8}>
            <Card>
                <Title level={3}>Total Open Volume</Title>
                <Statistic
                    value={totalVolumeOpen}
                />
            </Card>
        </Col>
        <Col span={8}>
            <Card>
                <Title level={3}>Total Winning Volume</Title>
                <Statistic
                    value={totalVolumeWin}
                />
            </Card>
        </Col>
        <Col span={8}>
            <Card>
                <Title level={3}>Total Losing Volume</Title>
                <Statistic
                    value={totalVolumeLoss}
                />
            </Card>
        </Col>
        <Col span={8}>
            <Card>
                <Title level={3}>Max Traded Volume <DescriptionIcon description={statisticsVolume.maxTraded} /></Title>
                <Statistic
                    value={maxVolume}
                    precision={0}
                />
            </Card>
        </Col>
        <Col span={8}>
            <Card>
                <Title level={3}>Average Volume per Trade</Title>
                <Statistic
                    value={averageVolume}
                    precision={2}
                />
            </Card>
        </Col>
        <Col span={8}>
            <Card>
                <Title level={3}>Average Winning Volume Traded <DescriptionIcon
                    description={statisticsVolume.averageWinningVol} />
                </Title>
                <Statistic
                    value={averageVolumeWin}
                    precision={2}
                />
            </Card>
        </Col>
        <Col span={8}>
            <Card>
                <Title level={3}>Average Losing Volume Traded <DescriptionIcon
                    description={statisticsVolume.averageLosingVol}
                /></Title>
                <Statistic
                    value={averageVolumeLoss}
                    precision={2}
                />
            </Card>
        </Col>
        <Col span={8}>
            <Card>
                <Title level={3}>Average Winning Volume Traded per Day <DescriptionIcon
                    description={statisticsVolume.averageWinningVolPerDay} /></Title>
                <Statistic
                    value={averageVolumeWinPerDay}
                    precision={2}
                />
            </Card>
        </Col>
        <Col span={8}>
            <Card>
                <Title level={3}>Average Losing Volume Traded per Day <DescriptionIcon
                    description={statisticsVolume.averageLosingVolPerDay} /></Title>
                <Statistic
                    value={averageVolumeLossPerDay}
                    precision={2}
                />
            </Card>
        </Col>
        <Col span={8}>
            <Card>
                <Title level={3}>Min Traded Volume <DescriptionIcon description={statisticsVolume.minTradedVol} /></Title>
                <Statistic
                    value={minVolume}
                    precision={0}
                />
            </Card>
        </Col>
        <Col span={8}>
            <Card>
                <Title level={3}>Average Volume per Day <DescriptionIcon
                    description={statisticsVolume.averageVolPerDay} /></Title>
                <Statistic
                    value={averageVolumePerDay}
                    precision={2}
                />
            </Card>
        </Col>
        <Col span={8}>
            <Card>
                <Title level={3}>Average Traded Volume per Month <DescriptionIcon
                    description={statisticsVolume.averageTradedVolPerMonth} /></Title>
                <Statistic
                    value={averageVolumePerMonth}
                    precision={2}
                />
            </Card>
        </Col>
        <Col span={8}>
            <Card>
                <Title level={3}>Average Traded Volume per Year <DescriptionIcon
                    description={statisticsVolume.averageTradedVolPerYear} /></Title>
                <Statistic
                    value={averageVolumePerYear}
                    precision={2}
                />
            </Card>
        </Col>
        <Col span={8}>
            <Card>
                <Title level={3}>Average Winning Volume Traded per Month <DescriptionIcon
                    description={statisticsVolume.averageWinningVolPerMonth} /></Title>
                <Statistic
                    value={averageVolumeWinPerMonth}
                    precision={2}
                />
            </Card>
        </Col>
        <Col span={8}>
            <Card>
                <Title level={3}>Average Winning Volume Traded per Year <DescriptionIcon
                    description={statisticsVolume.averageWinningVolPerYear} /></Title>
                <Statistic
                    value={averageVolumeWinPerYear}
                    precision={2}
                />
            </Card>
        </Col>
        <Col span={8}>
            <Card>
                <Title level={3}>Average Losing Volume Traded per Month <DescriptionIcon
                    description={statisticsVolume.averageLosingVolTradedPerMonth} /> </Title>
                <Statistic
                    value={averageVolumeLossPerMonth}
                    precision={2}
                />
            </Card>
        </Col>
        <Col span={8}>
            <Card title={<Title level={3}>Daily Volume</Title>}>
                <div style={{ height: '20vh' }}>
                    {dailyData && <DailyVolumeBarChart data={dailyData} />}
                </div>
            </Card>
        </Col>
        <Col span={8}>
            <Card>
                <Title level={3}>Average Losing Volume Traded per Year <DescriptionIcon
                    description={statisticsVolume.averageLosingVolTradedPerYear} /></Title>
                <Statistic
                    value={averageVolumeLossPerYear}
                    precision={2}
                />
            </Card>
        </Col>
    </Row>
);

VolumeStatistics.propTypes = {

  totalVolume: PropTypes.number,
  totalVolumeOpen: PropTypes.number,
  totalVolumeClosed: PropTypes.number,
  totalVolumeWin: PropTypes.number,
  totalVolumeLoss: PropTypes.number,
  averageVolume: PropTypes.number,
  averageVolumeWin: PropTypes.number,
  averageVolumeLoss: PropTypes.number,
  averageVolumePerDay: PropTypes.string,
  averageVolumeWinPerDay: PropTypes.string,
  averageVolumeLossPerDay: PropTypes.string,
  averageVolumePerMonth: PropTypes.string,
  averageVolumeWinPerMonth: PropTypes.string,
  averageVolumeLossPerMonth: PropTypes.string,
  averageVolumePerYear: PropTypes.string,
  averageVolumeWinPerYear: PropTypes.string,
  averageVolumeLossPerYear: PropTypes.string,
  maxVolume: PropTypes.number,
  minVolume: PropTypes.number,
  dailyData: PropTypes.array

};

export default VolumeStatistics;

// utils/getTimeDifference.js
import dayjs from 'dayjs';

const getTimeDifference = (date) => {

  const now = dayjs();
  const diff = now.diff(date, 'minute');

  if (diff < 1) {

    return 'just now';

  } if (diff < 60) {

    return `${diff} minutes ago`;

  } if (diff < 24 * 60) {

    return `${Math.floor(diff / 60)} hour${Math.floor(diff / 60) > 1 ? 's' : ''} ago`;

  } if (diff < 48 * 60) {

    return 'yesterday';

  }

  return dayjs(date).format('MMMM D, YYYY');

};

export default getTimeDifference;

import React from 'react';
import { Card, Col, Row, Space, Statistic, Typography } from 'antd';
import PropTypes from 'prop-types';

import DailyWinLossBarChart from '../../../../components/visualizations/daily-win-loss-bar-chart';
import WinLossPieChart from '../../../../components/visualizations/win-loss-pie-chart';
import { statisticsTrades } from '../../../../utilities/descriptions';
import DescriptionIcon from '../../../dashboard/components/description-icon';

const { Title, Text } = Typography;

const TradesStatistics = ({
  winLossRatio,
  totalTrades,
  totalOpenTrades,
  totalClosedTrades,
  avgTradesPerMonth,
  avgTradesPerYear,
  lastTradeDay,
  totalExecutions,
  maxExecutions,
  minExecutions,
  avgExecutions,
  totalLong,
  totalShort,
  totalDuration,
  avgTradeDuration,
  longestTradeDuration,
  fastestTradeDuration,
  longestOpenTradeDuration,
  shortestOpenTradeDuration,
  totalWins,
  totalLosses,
  totalBreakeven,
  winlossData,
  dailyData
}) => (
    <Row gutter={[24, 24]} style={{ width: '100%' }}>
        <Col span={8}>
            <Space direction='vertical' style={{ width: '100%' }}>
                <Card>
                    <Title level={3}>Total Number of Trades</Title>
                    <Statistic
                        value={totalTrades}
                    />
                </Card>
                <Card>
                    <Title level={3}> Average Trades per Month <DescriptionIcon description={statisticsTrades.averageTradesPerMonth} /></Title>
                    <Statistic
                        value={avgTradesPerMonth}
                    />
                </Card>
                <Card>
                    <Title level={3}>Average Trades per Year <DescriptionIcon description={statisticsTrades.averageTradesPerYear} /></Title>
                    <Statistic
                        value={avgTradesPerYear}
                    />
                </Card>
                <Card>
                    <Title level={3}>Total Duration <DescriptionIcon description={statisticsTrades.totalDuration} /></Title>
                    <Statistic
                        value={totalDuration}
                    />
                </Card>
            </Space>
        </Col>
        <Col span={8}>
            <Space direction='vertical' style={{ width: '100%' }}>
                <Card>
                    <Title level={3}>Total Number of Open Trades <DescriptionIcon description={statisticsTrades.totalOpenTrades} /></Title>
                    <Statistic
                        value={totalOpenTrades}
                    />
                </Card>
                <Card>
                    <Title level={3}>Total Number of Closed Trades</Title>
                    <Statistic
                        value={totalClosedTrades}
                    />
                </Card>
                <Card>
                    <Title level={3}>Total Number of Long Trades</Title>
                    <Statistic
                        title='Long Trades'
                        value={totalLong}
                    />
                </Card>
                <Card>
                    <Title level={3}>Total Number of Short Trades</Title>
                    <Statistic
                        value={totalShort}
                    />
                </Card>

            </Space>
        </Col>
        <Col span={8}>
            <Space direction='vertical' style={{ width: '100%' }}>
                <Card>
                    <Title level={3}>Total Number of Executed Trades <DescriptionIcon description={statisticsTrades.totalExecutedTrades} /></Title>
                    <Statistic
                        value={totalExecutions}
                    />
                </Card>
                <Card>
                    <Title level={3}>Average Executions per Trade <DescriptionIcon description={statisticsTrades.averageExecutionsPerTrade} /></Title>
                    <Statistic
                        precision={2}
                        value={avgExecutions}
                    />
                </Card>
                <Card>
                    <Title level={3}>Max Executions <Text type='secondary'>/ Trade</Text> <DescriptionIcon description={statisticsTrades.maxExecutionsPerTrade} /></Title>
                    <Statistic
                        title='Max Number of Executions Per Trade'
                        value={maxExecutions}
                    />
                </Card>
                <Card>
                    <Title level={3}>Min Executions <Text type='secondary'>/ Trade</Text> <DescriptionIcon description={statisticsTrades.minExecutionsPerTrade} /></Title>
                    <Statistic
                        title='Min Number of Executions Per Trade'
                        value={minExecutions}
                    />
                </Card>
            </Space>
        </Col>
        <Col span={8}>
            <Space direction='vertical' style={{ width: '100%' }}>
                <Card>
                    <Title level={3}>Average Trade Duration <DescriptionIcon description={statisticsTrades.averageTradeDuration} /></Title>
                    <Statistic value={avgTradeDuration} />
                </Card>
                <Card>
                    <Title level={3}>Longest Trade <Text type='secondary'>/ Trade</Text></Title>
                    <Statistic
                        title='Longest Trade Duration'
                        value={longestTradeDuration}
                    />
                </Card>
                <Card>
                    <Title level={3}>Fastest Trade <Text type='secondary'>/ Trade</Text></Title>
                    <Statistic
                        title='Fastest Trade Duration'
                        value={fastestTradeDuration}
                    />
                </Card>
                <Card>
                    <Title level={3}>Longest Open Trade <Text type='secondary'>/ Trade</Text></Title>
                    <Statistic
                        title='Longest Open Trade Duration'
                        value={longestOpenTradeDuration}
                    />
                </Card>
                <Card>
                    <Title level={3}>Last Trade <Text type='secondary'>Date</Text></Title>
                    <Statistic
                        title='Last Trade Imported'
                        value={lastTradeDay}
                    />
                </Card>
            </Space>
        </Col>
        <Col span={8}>
            <Space direction='vertical' style={{ width: '100%' }}>
                <Card>
                    <Title level={3}>Total Number of Win Trades</Title>
                    <Statistic
                        value={totalWins}
                    />
                </Card>
                <Card>
                    <Title level={3}>Total Number of Loss Trades</Title>
                    <Statistic
                        value={totalLosses}
                    />
                </Card>
                <Card>
                    <Title level={3}>Breakeven <Text type='secondary'>Total</Text></Title>
                    <Statistic
                        title='Net Zero Trades'
                        value={totalBreakeven}
                    />
                </Card>
                <Card>
                    <Title level={3}>Shortest Open Trade <Text type='secondary'>/ Trade</Text></Title>
                    <Statistic
                        title='Shortest Open Trade Duration'
                        value={shortestOpenTradeDuration}
                    />
                </Card>
            </Space>
        </Col>
        <Col span={8}>
            <Space direction='vertical' style={{ width: '100%' }}>
                <Card title={<Title level={3}>Win / Loss <Text type='secondary'>Ratio</Text> <DescriptionIcon description={statisticsTrades.winLossRatio} /></Title>}>
                    <Statistic
                        title='All Time'
                        precision={2}
                        value={winLossRatio}
                        valueStyle={winLossRatio > 1 ? {
                          color: '#29a373'
                        } : {
                          color: '#E57373'
                        }}
                    />
                    <div style={{ height: '227px' }}>
                        <WinLossPieChart data={winlossData} />
                    </div>
                </Card>
                <Card title={<Title level={3}>Daily Win / Loss</Title>}>
                    <div style={{ height: '215px' }}>
                        { dailyData && <DailyWinLossBarChart data={dailyData} /> }
                    </div>
                </Card>
            </Space>
        </Col>
    </Row>
);

TradesStatistics.propTypes = {

  winLossRatio: PropTypes.string,
  totalTrades: PropTypes.number,
  totalOpenTrades: PropTypes.number,
  totalClosedTrades: PropTypes.number,
  avgTradesPerMonth: PropTypes.string,
  avgTradesPerYear: PropTypes.string,
  lastTradeDay: PropTypes.string,
  totalExecutions: PropTypes.number,
  maxExecutions: PropTypes.number,
  minExecutions: PropTypes.number,
  avgExecutions: PropTypes.number,
  totalLong: PropTypes.number,
  totalShort: PropTypes.number,
  totalDuration: PropTypes.string,
  avgTradeDuration: PropTypes.string,
  longestTradeDuration: PropTypes.string,
  fastestTradeDuration: PropTypes.string,
  longestOpenTradeDuration: PropTypes.string,
  shortestOpenTradeDuration: PropTypes.string,
  totalWins: PropTypes.number,
  totalLosses: PropTypes.number,
  totalBreakeven: PropTypes.number,
  winlossData: PropTypes.array,
  dailyData: PropTypes.array

};

export default TradesStatistics;

// import { useQuery } from '@tanstack/react-query';
// import axios from 'axios';
import React from 'react';
// import { useParams } from 'react-router';

export default function AccountIndividualPage() {

  // let { id } = useParams();

  // const fetchAccount = (id) => axios.get(`http://localhost:5000/crud/account/${id}`).then(({ data }) => data);

  // const { data: account = []} = useQuery(['trade', id], () => fetchAccount(id));
  return (
      <>
          <h1>ACCOUNT</h1>
      </>
  );

}

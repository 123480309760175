import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Input, InputNumber, message, Popconfirm, Skeleton, Space, Table, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@tanstack/react-query';

import { useAccountStore, useSettingStore, useUserState } from '../../store';
import axios from '../../utilities/axios';
import { FORMAT, LOGS } from '../../utilities/functions';

import styles from './styles.module.scss';

const { Title } = Typography; // dependent on utc plugin

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const fetchCommissions = (accountId) => {

  if (!accountId) {

    return [];

  }

  const results = axios.get(`crud/commission/${accountId}`)
    .then(({ data }) => data)
    .catch((error) => {

      if (error.response.status === 404) {

        return [];

      }
      return error;

    });

  return results;

};

export default function CommissionsPage() {

  const { allAccounts, setAllAccounts, setAllCommissions } = useAccountStore((state) => state);
  const { advancedSettings, setAdvancedSettings } = useSettingStore((state) => state);
  const { getUser } = useUserState();
  const [removingCommission, setRemovingCommission] = useState('');
  const user = getUser();
  const [form] = Form.useForm();

  const fetchAccounts = () => axios.get('crud/account', { headers: { token: user?.token } }).then(({ data }) => data.map((a) => FORMAT.ACCOUNTS_BEAUTIFY(a)));

  useQuery(['accounts'], () => fetchAccounts().then(async (accounts) => {

    const results = await LOGS.processLogs(accounts, setAllAccounts);

    return results;

  }), { enabled: user.token !== undefined });

  const { data = [], refetch, isLoading } = useQuery(
    ['commissions'],
    () => fetchCommissions(user.id)
  );

  const deleteCommissionByID = (id, accountID) => axios.delete(`crud/commission/${id}`)
    .then(() => refetch())
    .then(() => {

      const savedCommission = advancedSettings.commissions[accountID];
      if (savedCommission === id) {

        let newSettings = advancedSettings.options[accountID] || [];

        const filteredSettings = newSettings.filter((item) => !['forceOverwriteCommission', 'applyFileCommissions'].includes(item));
        newSettings = [...filteredSettings, 'noCommission'];

        setAdvancedSettings('options', { ...advancedSettings.options, [accountID]: [...newSettings] });
        setAdvancedSettings('commissions', { ...advancedSettings.commissions, [accountID]: null });

      }
      message.success('Commission Deleted.');

    })
    .catch(() => {

      message.error('Something went wrong, please try again.');

    });

  const createCommission = (values) => axios.post('crud/commission', values).then(() => refetch());

  const mutateCreateCommission = useMutation(createCommission);

  const onFinish = (values) => {

    const val = { ...values };

    Object.entries(val).forEach(([key, value]) => {

      if (value === '') {

        val[key] = 0;

      }

    });
    val.userRef = user.id;

    mutateCreateCommission.mutateAsync(val).then(() => {

      form.resetFields();
      message.success('Commission profile created.');

    });
    refetch();

  };
  const onFinishFailed = () => message.error('Failed.');

  const confirmDeleteCommission = (e) => {

    setRemovingCommission(e._id);
    deleteCommissionByID(e._id, e.accountRef);

  };

  useEffect(() => {

    if (allAccounts) refetch();

  }, [allAccounts]);

  useEffect(() => {

    if (data.length > 0) setAllCommissions(data);

  }, [data]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Account',
      key: 'label',
      render: (e) => allAccounts.filter((a) => a.key === e?.accountRef)[0]?.label
    },
    {
      title: 'Options Base Commission',
      dataIndex: 'options_base_commission',
      key: 'options_base_commission',
      render: (e) => `$${e}`
    },
    {
      title: 'Options Base Fee',
      dataIndex: 'options_base_fee',
      key: 'options_base_fee',
      render: (e) => `$${e}`
    },
    {
      title: 'Options Per Share Commission',
      dataIndex: 'options_per_quantity_commission',
      key: 'options_per_quantity_commission',
      render: (e) => `$${e}`
    },
    {
      title: 'Options Per Share Fee',
      dataIndex: 'options_per_quantity_fee',
      key: 'options_per_quantity_fee',
      render: (e) => `$${e}`
    },
    {
      title: 'Options Commission',
      dataIndex: 'options_percentage_commission',
      key: 'options_percentage_commission',
      render: (e) => `${e}%`
    },
    {
      title: 'Options Fee',
      dataIndex: 'options_percentage_fee',
      key: 'options_percentage_fee',
      render: (e) => `${e}%`
    },
    {
      title: 'Stocks Base Commission',
      dataIndex: 'stock_base_commission',
      key: 'stock_base_commission',
      render: (e) => `$${e}`
    },
    {
      title: 'Stocks Base Fee',
      dataIndex: 'stock_base_fee',
      key: 'stock_base_fee',
      render: (e) => `$${e}`
    },
    {
      title: 'Stocks Per Share Commission',
      dataIndex: 'stock_per_quantity_commission',
      key: 'stock_per_quantity_commission',
      render: (e) => `$${e}`
    },
    {
      title: 'Stocks Per Share Fee',
      dataIndex: 'stock_per_quantity_fee',
      key: 'stock_per_quantity_fee',
      render: (e) => `$${e}`
    },
    {
      title: 'Stocks Commission',
      dataIndex: 'stock_percentage_commission',
      key: 'stock_percentage_commission',
      render: (e) => `${e}%`
    },
    {
      title: 'Stocks Fee',
      dataIndex: 'stock_percentage_fee',
      key: 'stock_percentage_fee',
      render: (e) => `${e}%`
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      render: (e) => <>
          <Popconfirm
              title='Delete Commission Profile?'
              description='Deleting this commission profile will remove it permanently. Are you sure?'
              onConfirm={() => {

                confirmDeleteCommission(e);

              }}
              okText='Yes'
              cancelText='No'
              placement='right'
        >
              <Tooltip title={<><WarningOutlined /> Remove Single Commission Profile</>} color='#E57373'>
                  <Button size='small' loading={removingCommission === e._id} type='outlined' shape='circle' icon={<DeleteOutlined />}>Remove</Button>
              </Tooltip>
          </Popconfirm>
      </>
    }
  ];

  return (
      <Space direction='vertical' align='start'>
          <Form
              name='commission-form'
              form={form}
              layout='horizontal'
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 24 }}
              style={{ minWidth: 580 }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete='off'
      >
              <Space className={styles.mainFields} direction='vertical' align='start'>
                  <Form.Item
                      label='Profile Name'
                      name='name'
                      labelCol={6}
                      rules={[
                        {
                          required: true,
                          message: 'Add a profile name to identify this commission rule.'
                        }
                      ]}
          >
                      <Input />
                  </Form.Item>
              </Space>

              <Space align='start' className={styles.commissionFields}>
                  <Space direction='vertical' align='start'>
                      <Title level={4}>COMMISSIONS</Title>
                      <Form.Item
                          label='Stocks - Base Commission'
                          name='stock_base_commission'
                          initialValue=''
                          rules={[
                            {
                              required: false,
                              message: 'Base commission charged for stocks.'
                            }
                          ]}>
                          <InputNumber
                              prefix='$'
                              min={0}
                              style={{
                                width: '100%'
                              }}
                              placeholder='0'
              />
                      </Form.Item>
                      <Form.Item
                          label='Stocks - Per Share Commission'
                          name='stock_per_quantity_commission'
                          initialValue=''
                          rules={[
                            {
                              required: false,
                              message: 'Per share commission charged for stocks.'
                            }
                          ]}>
                          <InputNumber
                              prefix='$'
                              min={0}
                              style={{
                                width: '100%'
                              }}
                              placeholder='0'
              />
                      </Form.Item>
                      <Form.Item
                          label='Stocks - % Commission'
                          name='stock_percentage_commission'
                          initialValue=''
                          rules={[
                            {
                              required: false,
                              message: 'Per share commission charged for stocks.'
                            }
                          ]}>
                          <InputNumber
                              prefix='%'
                              formatter={(value) => `${value}`}
                              min={0}
                              style={{
                                width: '100%'
                              }}
                              placeholder='0'
              />
                      </Form.Item>
                      <Form.Item
                          label='Options - Base Commission'
                          name='options_base_commission'
                          initialValue=''
                          rules={[
                            {
                              required: false,
                              message: 'Base commission charged for options.'
                            }
                          ]}>
                          <InputNumber
                              prefix='$'
                              min={0}
                              style={{
                                width: '100%'
                              }}
                              placeholder='0'
              />
                      </Form.Item>
                      <Form.Item
                          label='Options - Per Share Commission'
                          name='options_per_quantity_commission'
                          initialValue=''
                          rules={[
                            {
                              required: false,
                              message: 'Per share commission charged for options.'
                            }
                          ]}>
                          <InputNumber
                              prefix='$'
                              min={0}
                              style={{
                                width: '100%'
                              }}
                              placeholder='0'
              />
                      </Form.Item>
                      <Form.Item
                          label='Options - % Commission'
                          name='options_percentage_commission'
                          initialValue=''
                          rules={[
                            {
                              required: false,
                              message: 'Percentage commission charged for options.'
                            }
                          ]}>
                          <InputNumber
                              prefix='%'
                              formatter={(value) => `${value}`}
                              min={0}
                              style={{
                                width: '100%'
                              }}
                              placeholder='0'
              />
                      </Form.Item>
                  </Space>
                  <Space direction='vertical' align='start' className={styles.feesFields}>
                      <Title level={4}>FEES</Title>
                      <Form.Item
                          label='Stocks - Base Fee'
                          name='stock_base_fee'
                          initialValue=''
                          rules={[
                            {
                              required: false,
                              message: 'Base fee charged for stocks.'
                            }
                          ]}>
                          <InputNumber
                              prefix='$'
                              min={0}
                              style={{
                                width: '100%'
                              }}
                              placeholder='0'
              />
                      </Form.Item>
                      <Form.Item
                          label='Stocks - Per Share Fee'
                          name='stock_per_quantity_fee'
                          initialValue=''
                          rules={[
                            {
                              required: false,
                              message: 'Per share fee charged for stocks.'
                            }
                          ]}>
                          <InputNumber
                              prefix='$'
                              min={0}
                              style={{
                                width: '100%'
                              }}
                              placeholder='0'
              />
                      </Form.Item>
                      <Form.Item
                          label='Stocks - % Fee'
                          name='stock_percentage_fee'
                          initialValue=''
                          rules={[
                            {
                              required: false,
                              message: 'Per share fee charged for stocks.'
                            }
                          ]}>
                          <InputNumber
                              prefix='%'
                              formatter={(value) => `${value}`}
                              min={0}
                              style={{
                                width: '100%'
                              }}
                              placeholder='0'
              />
                      </Form.Item>
                      <Form.Item
                          label='Options - Base Fee'
                          name='options_base_fee'
                          initialValue=''
                          rules={[
                            {
                              required: false,
                              message: 'Base fee charged for options.'
                            }
                          ]}>
                          <InputNumber
                              prefix='$'
                              min={0}
                              style={{
                                width: '100%'
                              }}
                              placeholder='0'
              />
                      </Form.Item>

                      <Form.Item
                          label='Options - Per Share Fee'
                          name='options_per_quantity_fee'
                          initialValue=''
                          rules={[
                            {
                              required: false,
                              message: 'Per share fee charged for options.'
                            }
                          ]}>
                          <InputNumber
                              prefix='$'
                              min={0}
                              style={{
                                width: '100%'
                              }}
                              placeholder='0'
              />
                      </Form.Item>

                      <Form.Item
                          label='Options - % Fee'
                          name='options_percentage_fee'
                          initialValue=''
                          rules={[
                            {
                              required: false,
                              message: 'Per share fee charged for stocks.'
                            }
                          ]}>
                          <InputNumber
                              prefix='%'
                              formatter={(value) => `${value}`}
                              min={0}
                              style={{
                                width: '100%'
                              }}
                              placeholder='0'
              />
                      </Form.Item>
                  </Space>
              </Space>

              <Form.Item
                  wrapperCol={{
                    offset: 8,
                    span: 16
                  }}>
                  <Button type='primary' htmlType='submit' loading={mutateCreateCommission.isLoading}>
                      Add New Rule
                  </Button>
              </Form.Item>
          </Form>
          <Title level={5}>COMMISSIONS & FEES PROFILES</Title>
          <Card className={styles.commissionsCard}>
              {isLoading ? <Skeleton active paragraph={{ rows: 12 }} /> : <Table
                  columns={columns}
                  dataSource={data}
                  scroll={{ y: '20rem' }}
                  rowKey='_id'
                  pagination={false}
        />}
          </Card>
      </Space>
  );

}

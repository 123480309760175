import React from 'react';
import PropTypes from 'prop-types';

import { ResponsiveBarCanvas } from '@nivo/bar';

import './styles.scss';

const HorizontalBarChart = ({ data, legend }) => (

    <div className='horizontalBarChart'>
        <div className='chartContainer'>
            <ResponsiveBarCanvas
                data={[
                  {
                    green: data?.greenData || 0,
                    gray: data?.grayData || 0,
                    red: data?.redData || 0
                  }
                ]}
                keys={['green', 'gray', 'red']}
                colors={['#54B58F', '#4971DC', '#E57373']}
                layout='horizontal'
                enableLabel={false}
                axisLeft={null}
                enableGridY={false}
                axisBottom={null}
                tooltip={() => null}
              />
        </div>
        <div className='horizontalBarChartLegend'>
            <span style={{ color: '#54B58F' }}>{legend?.left}</span>
            <span style={{ color: '#E57373' }}>{legend?.right}</span>
        </div>
    </div>

);

HorizontalBarChart.propTypes = {

  data: {
    greenData: PropTypes.number,
    grayData: PropTypes.number,
    redData: PropTypes.number
  },
  legend: {
    left: PropTypes.string,
    right: PropTypes.string
  }

};

export default HorizontalBarChart;

import React from 'react';
import { Col, Row, Space, Tabs, Typography } from 'antd';
import dayjs from 'dayjs';
import qs from 'qs';

import { useQuery } from '@tanstack/react-query';

import { useSettingStore } from '../../store';
import axios from '../../utilities/axios';
import { FORMAT } from '../../utilities/functions';

import PnLStatistics from './components/pnl-statistics';
import SummaryTable from './components/summary-table';
import SymbolStatistics from './components/symbol-statistics';
import TradesStatistics from './components/trades-statistics';
import VolumeStatistics from './components/volume-statistics';

import './styles.scss';

const { Title, Text } = Typography;

const collapseCategories = (summary) => [
  {
    key: '1',
    label: 'P&L Statistics',
    children: <PnLStatistics {...summary} />
  },
  {
    key: '2',
    label: 'Trades Statistics',
    children: <TradesStatistics {...summary} winlossData={[
      {
        id: 'Win',
        label: 'Win',
        value: summary.totalWins,
        color: '#29a373'
      },
      {
        id: 'Loss',
        label: 'Loss',
        value: summary.totalLosses,
        color: '#E57373'
      }
    ]} />
  },
  {
    key: '3',
    label: 'Symbol Statistics',
    children: <SymbolStatistics {...summary} />
  },
  {
    key: '4',
    label: 'Volume Statistics',
    children: <VolumeStatistics {...summary} />
  },
  {
    key: '5',
    label: 'Summary Table',
    children: <SummaryTable {...summary} />
  }
];

export default function SummaryPage() {

  const { filter } = useSettingStore((state) => state);

  const fetchAccountSummary = () => axios.get('stats/summary', {
    params: {
      accounts: filter.accounts,
      assetType: filter.assetType,
      tags: filter.tags,
      symbols: filter.symbols,
      status: filter.status,
      side: filter.side,
      startDate: filter.dateRange ? filter.dateRange[0]?.toString() : dayjs().add(-5, 'y').toDate(),
      endDate: filter.dateRange ? filter.dateRange[1]?.toString() : dayjs().toDate()
    },
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' })
  }).then((response) => {

    let responseData = null;

    if (response.status === 200) {

      responseData = FORMAT.SUMMARY_BEAUTIFY(response.data);

    }

    return responseData;

  }).catch((error) => {

    console.error('Error:', error);

  });

  const { isLoading, data: summary } = useQuery(['summary-account', filter], () => fetchAccountSummary());

  if (isLoading) return <></>;

  if (!summary) {

    return (
        <Space direction='vertical' className='no-data' wrap>
            <Title>No data available.</Title>
            <Text>Select different filters or upload trade logs by using the <strong>Import Trades</strong> button.</Text>
        </Space>
    );

  }

  return (
      <>
          <Row gutter={[24, 24]} style={{ width: '100%' }}>
              <Col span={24}>
                  <Tabs
                      type='card'
                      items={collapseCategories(summary)}
              />
              </Col>
          </Row>
      </>
  );

}

import config from './config';

// TODO: fix this after we fixed all of projects:
// const isDevelopment = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
const isDevelopment = false;

// consider this when Earth does not exist (or our project):
const farFutureDate = new Date('December 31, 2999 23:59:59').toUTCString();
const domain = JSON.parse(config.get('isLocalhost') || 'false') ? 'localhost' : '.tradingterminal.com';

function setCookie(name, value, expire = farFutureDate) {

  const cookieContent = `${isDevelopment ? 'staging-' : ''}${name}=${JSON.stringify(value)};expires=${expire}; path=/; domain=${domain};`;

  document.cookie = cookieContent;

}

function getCookie(name) {

  const cookieArray = document.cookie.split(';');

  for (let i = 0; i < cookieArray.length; i += 1) {

    const cookie = cookieArray[i].trim();
    const startShouldBe = `${isDevelopment ? 'staging-' : ''}${name}=`;
    if (cookie.startsWith(startShouldBe)) {

      // console.log('return', cookie.substring(startShouldBe.length));
      return decodeURIComponent(cookie.substring(startShouldBe.length));

    }

  }
  return null;

}

function removeCookie(name) {

  document.cookie = `${isDevelopment ? 'staging-' : ''}${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain};`;

}

export {
  getCookie,
  setCookie,
  removeCookie
};

import React from 'react';
import { Col, Descriptions, Row } from 'antd';
import PropTypes from 'prop-types';

import { statisticsSummary } from '../../../../utilities/descriptions';
import { FORMAT } from '../../../../utilities/functions';
import DescriptionIcon from '../../../dashboard/components/description-icon';

const SummaryTable = ({
  totalTrades,
  totalWins,
  totalLosses,
  totalBreakeven,
  averageNetPnLWin,
  averageNetPnLLoss,
  avgTradesPerDay,
  winLossRatio,
  totalLong,
  totalShort,
  totalExecutions,
  avgExecutions,
  netPnL,
  avgPnL,
  totalDuration,
  avgTradeDuration
}) => (
    <Row gutter={[24, 24]} style={{ width: '100%' }}>
        <Col span={24}>
            <Descriptions bordered column={1} size='small'>
                <Descriptions.Item label='Total Number of Trades'>{totalTrades}</Descriptions.Item>
                <Descriptions.Item label='Total Number of Win Trades'>{totalWins}</Descriptions.Item>
                <Descriptions.Item label='Total Number of Loss Trades'>{totalLosses}</Descriptions.Item>
                <Descriptions.Item
                    label={
                        <>
                            <span>Winning Percentage</span>
                            &nbsp;&nbsp;
                            <DescriptionIcon
                                description={statisticsSummary.tradeWin} />
                        </>
                      }>
                    {((totalWins / totalTrades) * 100).toFixed(2)}%
                </Descriptions.Item>
                <Descriptions.Item
                    label={
                        <>
                            <span>Average Winner</span>
                            &nbsp;&nbsp;
                            <DescriptionIcon
                                description={statisticsSummary.averageWinner} />
                        </>
                  }>
                    {FORMAT.NUMBER_TO_CURRENCY(averageNetPnLWin)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={
                        <>
                            <span>Average Loser</span>
                            &nbsp;&nbsp;
                            <DescriptionIcon
                                description={statisticsSummary.averageLoser} />
                        </>
                              }>{FORMAT.NUMBER_TO_CURRENCY(averageNetPnLLoss)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={
                        <>
                            <span>Average Trades per Day</span>
                            &nbsp;&nbsp;
                            <DescriptionIcon
                                description={statisticsSummary.averageTradesPerDay}
                            />
                        </>
                }
                >
                    {avgTradesPerDay}
                </Descriptions.Item>
                <Descriptions.Item
                    label={<>
                        <span>Total Number of Breakeven Trades</span>
                        &nbsp;&nbsp;
                        <DescriptionIcon
                            description={statisticsSummary.totalNumberOfBreakeven} />
                    </>}>
                    {totalBreakeven}
                </Descriptions.Item>
                <Descriptions.Item
                    label={
                        <>
                            <span>Win / Loss Ratio</span>
                        &nbsp;&nbsp;
                            <DescriptionIcon description={statisticsSummary.winLossRatio} />
                        </>

                      }>
                    {winLossRatio}
                </Descriptions.Item>
                <Descriptions.Item label='Long Trades'>{totalLong}</Descriptions.Item>
                <Descriptions.Item label='Total Number of Short Trades'>{totalShort}</Descriptions.Item>
                <Descriptions.Item
                    label={
                        <>
                            <span>Total Number of Executed Trades</span>
                            &nbsp;&nbsp;
                            <DescriptionIcon
                                description={statisticsSummary.totalNumberOfExecutedTrades} />
                        </>
                  }>
                    {totalExecutions}
                </Descriptions.Item>
                <Descriptions.Item
                    label={
                        <>
                            <span>Average Executions Per Trade</span>
                            &nbsp;&nbsp;
                            <DescriptionIcon
                                description={statisticsSummary.averageExecutionsPerTrade} />
                        </>
                  }>
                    {avgExecutions}
                </Descriptions.Item>
                <Descriptions.Item
                    label={
                        <>
                            <span>Net P&L</span>
                            &nbsp;&nbsp;
                            <DescriptionIcon
                                description={statisticsSummary.netPnL}
                            />
                        </>
                }
                >
                    {netPnL}
                </Descriptions.Item>
                <Descriptions.Item
                    label={
                        <>
                            <span>Average Net P&L</span>
                            &nbsp;&nbsp;
                            <DescriptionIcon
                                description={statisticsSummary.averageNetPnL}
                        />
                        </>
                }
                >
                    {avgPnL}
                </Descriptions.Item>
                <Descriptions.Item label={
                    <>
                        <span>Total Duration</span>
                        &nbsp;&nbsp;
                        <DescriptionIcon
                            description={statisticsSummary.totalDuration}
                        />
                    </>
                }
                >
                    {totalDuration}
                </Descriptions.Item>
                <Descriptions.Item
                    label=
                        {
                            <>
                                <span>Average Trade Duration</span>
                                &nbsp;&nbsp;
                                <DescriptionIcon
                                    description={statisticsSummary.averageTradeDuration}
                                />
                            </>
                }
                >
                    {avgTradeDuration}
                </Descriptions.Item>
            </Descriptions>
        </Col>

    </Row>
);

SummaryTable.propTypes = {

  totalTrades: PropTypes.number,
  totalWins: PropTypes.number,
  totalLosses: PropTypes.number,
  averageNetPnLWin: PropTypes.number,
  averageNetPnLLoss: PropTypes.number,
  avgTradesPerDay: PropTypes.string,
  totalBreakeven: PropTypes.number,
  winLossRatio: PropTypes.string,
  totalLong: PropTypes.number,
  totalShort: PropTypes.number,
  totalExecutions: PropTypes.number,
  avgExecutions: PropTypes.number,
  netPnL: PropTypes.string,
  avgPnL: PropTypes.string,
  totalDuration: PropTypes.string,
  avgTradeDuration: PropTypes.string

};

export default SummaryTable;

import { API } from '../../../utilities/functions';

// DatafeedConfiguration implementation
const configurationData = {
  // Represents the resolutions for bars supported by your datafeed
  supported_resolutions: ['1', '5', '15', '60', '1D', '1W', '1M'],
  has_intraday: true,
  has_weekly_and_monthly: false,
  intraday_multipliers: ['1', '2', '5'],
  supports_marks: true
};

// Obtains all symbols for all exchanges supported by CryptoCompare API
async function getAllSymbols() {

  // const data = await API.getFullSymbolList();
  // const allSymbols = data.map((item) => ({
  //     symbol: item.symbol,
  //     full_name: item.symbol,
  //     description: item.name,
  //     exchange: 'NYSE',
  //     type: 'stock',
  // }))

  const data = await API.getTickers();
  const allSymbols = data.map((item) => ({
    symbol: item.symbol,
    full_name: item.companyName,
    description: item.companyName,
    // exchange: 'NYSE',
    type: 'stock'
  }));
  return allSymbols;

}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  onReady: (callback) => {

    // console.log('[onReady]: Method call');
    setTimeout(() => callback(configurationData));

  },

  searchSymbols: async (
    // userInput,
    // exchange,
    _symbolType,
    onResultReadyCallback
  ) => {

    // console.log('[searchSymbols]: Method call');
    const symbols = await getAllSymbols();
    onResultReadyCallback(symbols);

  },

  resolveSymbol: async (
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback
  ) => {

    // console.log('[resolveSymbol]: Method call', symbolName);
    const symbols = await getAllSymbols();
    const symbolItem = symbols.find(({ symbol }) => symbol === symbolName);
    if (!symbolItem) {

      // console.log('[resolveSymbol]: Cannot resolve symbol', symbolName);
      onResolveErrorCallback('Cannot resolve symbol');
      return;

    }
    // Symbol information object
    const symbolInfo = {
      ticker: symbolItem.full_name,
      name: symbolItem.symbol,
      description: symbolItem.description,
      type: symbolItem.type,
      session: '0400-1600',
      timezone: 'America/New_York',
      // exchange: symbolItem.exchange,
      minmov: 1,
      pricescale: 100,
      has_intraday: configurationData.has_intraday,
      visible_plots_set: 'ohlc',
      has_weekly_and_monthly: configurationData.has_weekly_and_monthly,
      supported_resolutions: configurationData.supported_resolutions,
      volume_precision: 2,
      data_status: 'endofday',
      delay: -1,
      subsession_id: 'extended',
      subsessions: [
        {
          description: 'Regular Trading Hours',
          id: 'regular',
          session: '0930-1600'
        },
        {
          description: 'Extended Trading Hours',
          id: 'extended',
          session: '0400-1600'
        },
        {
          description: 'Pre-market',
          id: 'premarket',
          session: '0400-0930'
        }
      ]
    };
    // console.log('[resolveSymbol]: Symbol resolved', symbolName);
    onSymbolResolvedCallback(symbolInfo);

  },

  getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {

    const { from, to } = periodParams;
    let timespan = 'minute';

    if (resolution.includes('D')) {

      timespan = 'day';

    } else if (resolution.includes('M')) {

      timespan = 'month';

    } else if (resolution.includes('W')) {

      timespan = 'week';

    } else if (resolution === '60') {

      timespan = 'hour';

    }

    // console.log('[getBars]: Method call', symbolInfo, resolution, from, to);
    const urlParameters = {
      ticker: symbolInfo.name,
      multiplier: 1,
      timespan,
      fromDate: from,
      toDate: to
    };
    try {

      const data = await API.getAggregateData(urlParameters);

      if (data.length === 0) {

        // "noData" should be set if there is no data in the requested period
        onHistoryCallback([], { noData: true });
        return;

      }

      let bars = [];
      data.forEach((bar) => {

        if (bar.t >= from * 1000 && bar.t < to * 1000) {

          bars = [...bars, {
            time: bar.t,
            low: bar.l,
            high: bar.h,
            open: bar.o,
            close: bar.c,
            volume: bar.v
          }];

        }

      });
      // console.log(`[getBars]: returned ${bars.length} bar(s)`);
      onHistoryCallback(bars, { noData: false });

    } catch (error) {

      // console.log('[getBars]: Get error', error);
      onErrorCallback(error);

    }

  }

  // getMarks: (symbolInfo, startDate, endDate, onDataCallback, resolution) => {
  //     console.log('getMarks');
  //     const marks = useSettingStore.getState().marks;
  //     onDataCallback(marks)
  // },
};

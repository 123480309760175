import React, { useState } from 'react';
import { Drawer, Space } from 'antd';
import PropTypes from 'prop-types';

import UploadBox from '../../../../components/upload-box';
import { useAccountStore } from '../../../../store';

export default function ImportDrawer({ open, onClose }) {

  const { allAccounts } = useAccountStore((state) => state);
  const [processing, setProcessing] = useState(false);

  return (<>
      {allAccounts && allAccounts.length > 0 &&
      <Drawer
          title='Upload Trade Logs'
          placement={'right'}
          closable={false}
          onClose={onClose}
          open={open}
          key={'right'}
          maskClosable={!processing} >
          <Space size={[0, 8]} direction='vertical'>
              <UploadBox processing={processing} setProcessing={setProcessing} />
          </Space>
      </Drawer>
  }

  </>
  );

}

ImportDrawer.propTypes = {

  open: PropTypes.bool,
  onClose: PropTypes.func

};

import React from 'react';
import { Modal } from 'antd';

import { ExclamationCircleOutlined } from '@ant-design/icons';

const showDeleteConfirm = (onConfirm, label, title) => {

  Modal.confirm({
    title: title || `Are you sure to delete "${label}"?`,
    icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
    okText: 'Delete',
    okType: 'danger',
    cancelText: 'Cancel',
    onOk() {

      onConfirm(true);

    },
    onCancel() {

      onConfirm(false);

    },
    okButtonProps: {
      style: { backgroundColor: '#F23645', borderColor: '#F23645', color: 'white' }
    }
  });

};

export default showDeleteConfirm;

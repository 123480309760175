import React from 'react';
import { Input } from 'antd';
import PropTypes from 'prop-types';

import './styles.scss';

const { TextArea } = Input;

export default function NotesEditor({ content, onChange }) {

  return <TextArea
      showCount
      maxLength={2000}
      onChange={(e) => onChange(e.target.value)}
      placeholder='Enter a new note'
      style={{
        height: 120,
        resize: 'none'
      }}
      defaultValue={content || ''}
      value={content || ''}
  />;

}

NotesEditor.propTypes = {

  onChange: PropTypes.func,
  content: PropTypes.any

};

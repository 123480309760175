import React from 'react';
import PropTypes from 'prop-types';

import { ResponsivePie } from '@nivo/pie';

const WinLossPieChart = ({ data }) => (
    <ResponsivePie
        theme={{
          fontSize: 14,
          textColor: '#FFF',
          tooltip: {
            container: {
              backgroundColor: '#263238D9',
              color: '#e0e3eb',
              fontWeight: 400,
              borderRadius: '0.5rem',
              padding: '0.5rem',
              lineHeight: 'normal',
              fontSize: '0.75rem'
            }
          }
        }}
        data={data}
        margin={{ top: 30, right: 0, bottom: 0, left: 0 }}
        innerRadius={0.5}
        padAngle={1}
        cornerRadius={7}
        colors={(d) => d.data.color}
        enableArcLinkLabels={false}
        arcLabel={(e) => `${e.label} (${e.value})`}
        arcLabelsSkipAngle={10}
        animate={false}
        legends={[]}

    />
);

WinLossPieChart.propTypes = {

  data: PropTypes.array

};

export default WinLossPieChart;

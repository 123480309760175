/* eslint-disable max-len */
exports.dashboard = {
  netPnL: 'The total aggregate profit & loss for all trades after tax and commissions matching the current filter.',
  averageWinner: 'The average profit earned on winning trades within a specified period of time. To sum up the net profits of all winning trades and then divide by the total number of winning trades. ',
  averageLoser: 'The average loss incurred on losing trades within a specified period of time. To sum up the losses of all losing trades and then divide by the total number of losing trades.',
  averageWinnerLoser: 'Average winner(profits) divided by average loser (losses)',
  averageTradesPerDay: 'average number of trades executed within a single trading day. Summing up the total number of trades executed over a certain period divided by the total number of trading days in that period.',
  tradeWin: 'The number of winning trades divided by the number of total trades.',
  dayWin: 'The number of profitable days divided by the number of trading days.',
  largestWin: 'The largest profit of a single trade, out of all trades matching the current filter.',
  largestLoss: 'The largest loss of a single trade, out of all trades matching the current filter.',
  runningPnl: 'Accumulative profit or loss over the time',
  dailyPnl: 'Profit or loss of each day',
  totalGainLoss: 'The total aggregate profit & loss for all trades matching the current filter.',
  averageGainLossPerTrade: 'It represents the average amount of net profit gained or lost per trade executed over a specific period. Calculating this metric involves dividing the total net profits (or losses) generated from all trades by the total number of trades executed.',
  totalNumberOfTrades: 'Total number of executed trades regardless of being open or closed',
  numberOfWinningTrades: 'Number of trades resulted in net profit',
  numberOfLosingTrades: 'Number of trades resulted in net loss',
  maxConsecutiveWins: 'The maximum number of consecutive trades matching the current filter with a P&L greater than zero. For the purposes of determining consecutive trades, trades are ordered by start date/time.',
  maxConsecutiveLosses: 'The maximum number of consecutive trades matching the current filter with a P&L less than zero. For the purposes of determining consecutive trades, trades are ordered by start date/time.',
  averageHoldTimeWinTrades: 'The average length of time a winning trade was held, averaged across all winning trades matching the current filter.',
  averageHoldTimeLossTrades: 'The average length of time a losing trade was held, averaged across all losing trades matching the current filter.',
  winLossRatio: 'The average length of time a losing trade was held, averaged across all losing trades matching the current filter.',
  totalCommissionsFees: 'Total commission was paid matching the current filter.'
};

exports.statisticsSummary = {
  totalNumberOfBreakeven: 'Net zero profit trades',
  winLossRatio: 'Compares the number of winning trades to the number of losing trades. It\'s calculated by dividing the number of winning trades by the number of losing trades.',
  tradeWin: 'The percentage of winning trades out of the total number of trades executed within a specified period of time. The number of winning trades divided by the number of total trades.',
  averageWinner: 'The average profit earned on winning trades within a specified period of time. To sum up the net profits of all winning trades and then divide by the total number of winning trades. ',
  averageLoser: 'The average loss incurred on losing trades within a specified period of time. To sum up the losses of all losing trades and then divide by the total number of losing trades.',
  totalNumberOfExecutedTrades: 'Total number of trades executed within a specified period of time.',
  averageExecutionsPerTrade: 'The average number of executions (or filled orders) per trade within a specified period of time. Dividing the total number of executions by the total number of trades executed during the specified period.',
  netPnL: 'The sum of all profits and losses that have been realized from closed trades within a specified period of time.',
  averageNetPnL: 'The average profit or loss per trade that has been realized from closed trades within a specified period of time. Dividing the Total Realized P&L by the total number of trades executed during the specified period.',
  totalDuration: 'The cumulative length of time that all trades were held open within a specified period.',
  averageTradeDuration: 'The average length of time that trades are held open before being closed. Summing the durations of all individual trades in terms of hours divide by the total number of trades.',
  averageTradesPerDay: 'Average number of trades executed within a single trading day. Summing up the total number of trades executed over a certain period divided by the total number of trading days in that period.'
};

exports.statisticsPnL = {
  pnlTotal: 'The total aggregate profit & loss for all trades after atx and commissions matching the current filter.',
  averagePnLPerTrade: 'The average profit or loss generated per trade over a specific period of time. To sum up the profits and losses of all trades within the given period (net) and then divide by the total number of trades.',
  averagePnLPerDay: 'The average net profit or loss generated per day over a specific period of time. To sum up the profits and losses of all trading days within the given period and then divide by the total number of trading days.',
  averagePnLPerMonth: 'Average Net Profit & Loss per month, is a metric used in trading to assess the average profit or loss generated per month over a specific period of time. To sum up the profits and losses of all trading months within the given period and then divide by the total number of trading months.',
  averagePnLPerYear: 'The average net profit or loss generated per year over a specific period of time. To sum up the profits and losses of all trading years within the given period and then divide by the total number of trading years.',
  maxPnLPerTrade: 'To determine the highest profit or lowest loss achieved from a single trade within a specified period of time.',
  minPnLPerTrade: 'The lowest profit or highest loss incurred from a single trade within a specified period of time.',
  maxPnLPerDay: 'The highest profit or loss achieved on a single trading day within a specified period of time. To identify the highest profit (positive value) or the lowest loss (negative value) among all trading days within the specified period.',
  minPnLPerDay: 'The lowest profit or highest loss incurred on a single trading day within a specified period of time. To identify the lowest profit (positive value) or the highest loss (negative value) among all trading days within the specified period.',
  maxPnLPerMonth: 'The highest profit or loss achieved within a single trading month over a specified period of time.',
  minPnLPerMonth: 'The lowest profit or highest loss incurred within a single trading month over a specified period of time.',
  maxPnLPerYear: 'The highest profit or lowest loss achieved within a single trading year over a specified period of time.',
  minPnLPerYear: 'The lowest profit or highest loss incurred within a single trading year over a specified period of time.',
  dailyPnl: 'The net profit or loss generated by a trading account on a daily basis over a specified period of time.',
  netPnL: 'Chart'
};

exports.statisticsTrades = {
  totalOpenTrades: 'Total number of open trades over a specific period of time.',
  totalExecutedTrades: 'Total number of trades executed within a specified period of time.',
  averageTradesPerMonth: 'The average number of trades per month over a specific period of time. Summing up the total number of trades during the specified period and then divide by the total number of months in that period.',
  averageExecutionsPerTrade: 'The average number of executions (or filled orders) per trade within a specified period of time. Dividing the total number of executions by the total number of trades executed during the specified period.',
  averageTradesPerYear: 'The average number of trades per year over a specific period of time. Summing up the total number of trades during the specified period divided by the total number of years in that period.',
  maxExecutionsPerTrade: 'The maximum number of times a single trade has been executed or filled. It measures how many partial fills or executions occurred for a single trade order.',
  minExecutionsPerTrade: 'The minimum number of times a single trade has been executed or filled.',
  totalDuration: 'The cumulative length of time that all trades were held open within a specified period.',
  averageTradeDuration: 'The average length of time that trades are held open before being closed. Summing the durations of all individual trades in terms of hours divide by the total number of trades.',
  winLossRatio: 'Compares the number of winning trades to the number of losing trades. It\'s calculated by dividing the number of winning trades by the number of losing trades.'
};

exports.statisticsSymbol = {
  mostTraded: 'The symbol with the most number of trades',
  highestTradedVolume: 'The symbol with the highest traded volume',
  mostWinningTrade: 'The symbol with the most number of win trades',
  mostLosingTrade: 'The symbol with the most number of loss trades',
  mostProfitable: 'The most profitable symbol',
  leastProfitable: 'The least profitable symbol'
};

exports.statisticsVolume = {
  maxTraded: 'The volume of a trade with the highest volume',
  averageWinningVol: 'The average volume of shares traded on winning trades. Summing up the total volume traded on all winning trades divided by the total number of winning trades.',
  averageLosingVol: 'The average volume of shares traded on losing trades. Summing up the total volume traded on all winning trades divided by the total number of losing trades.',
  averageWinningVolPerDay: 'The average volume of shares traded on winning trades per trading day.',
  averageLosingVolPerDay: 'The average volume of shares traded on losing trades per day. Summing up the total volume of shares traded on losing trades over the specified period divided by the total number of days in that period.',
  minTradedVol: 'The volume of a trade with the minimum volume',
  averageVolPerDay: 'The average daily trading volume over a specified period of time. Summing up the total volume traded over the specified period divided by the total number of days in that period.',
  averageTradedVolPerMonth: 'The average monthly trading volume over a specified period of time. Summing up the total volume traded over the specified period divided by the total number of months in that period.',
  averageTradedVolPerYear: 'The average daily trading volume over a specified period of time. Summing up the total volume traded over the specified period divided by the total number of years in that period.',
  averageWinningVolPerMonth: 'The average volume of shares traded on winning trades per day. Summing up the total volume of shares traded on winning trades over the specified period divided by the total number of months in that period.',
  averageWinningVolPerYear: 'The average volume of shares traded on winning trades per day. Summing up the total volume of shares traded on winning trades over the specified period divided by the total number of years in that period.',
  averageLosingVolTradedPerMonth: 'The average volume of shares traded on losing trades per day. Summing up the total volume of shares traded on losing trades over the specified period divided by the total number of months in that period.',
  averageLosingVolTradedPerYear: 'The average volume of shares traded on losing trades per day. Summing up the total volume of shares traded on losing trades over the specified period divided by the total number of years in that period.'
};

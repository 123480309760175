import React from 'react';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';

import { InfoCircleOutlined } from '@ant-design/icons';

export default function DescriptionIcon({ description }) {

  return (

      <Tooltip color='white' overlayInnerStyle={{ color: 'black', fontSize: '0.8rem' }} title={description} trigger='click'>
          <InfoCircleOutlined style={{ color: '#667085', fontSize: '0.9rem', verticalAlign: 'middle' }}/>
      </Tooltip>
  );

}

DescriptionIcon.propTypes = {

  description: PropTypes.string

};

import React from 'react';
import { Col, Descriptions, Row } from 'antd';
import PropTypes from 'prop-types';

import DescriptionIcon from '../../routes/dashboard/components/description-icon';

const DashboardSummaryTable = ({ data }) => (
    <Row gutter={[24, 24]} style={{ width: '100%', paddingTop: '3.375rem' }}>
        <Col span={24}>
            <Descriptions bordered column={1} size='small'>
                {data && data.map((item) => (
                    <Descriptions.Item
                        key={item.label}
                        label={<><strong>{item.label}</strong>&nbsp;&nbsp;{item.description && <DescriptionIcon description={item.description} />}</>}>{item.value}
                    </Descriptions.Item>
                ))}
            </Descriptions>
        </Col>

    </Row>
);

DashboardSummaryTable.propTypes = {

  data: PropTypes.array

};

export default DashboardSummaryTable;

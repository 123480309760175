import React from 'react';
import { Card, Col, Row, Statistic, Tag, Typography } from 'antd';
import PropTypes from 'prop-types';

import { statisticsSymbol } from '../../../../utilities/descriptions';
import { FORMAT } from '../../../../utilities/functions';
import DescriptionIcon from '../../../dashboard/components/description-icon';

const { Title } = Typography;

const SymbolStatistics = ({
  mostProfitableByTrades,
  leastProfitableByTrades,
  mostProfitableByPnL,
  leastProfitableByPnL,
  maxTradesPerSymbol,
  maxTradesByVolumePerSymbol
}) => {

  const redColor = '#E57373';
  const greenColor = '#29a373';
  const mostProfitableByPnLColor = FORMAT.CURRENCY_TO_NUMBER(mostProfitableByPnL?.PnL) > 0 ? { color: greenColor } : { color: redColor };
  const leastProfitableByPnLColor = FORMAT.CURRENCY_TO_NUMBER(leastProfitableByPnL?.PnL) > 0 ? { color: greenColor } : { color: redColor };
  return (

      <Row gutter={[24, 24]} style={{ width: '100%' }}>
          <Col span={8}>
              <Card>
                  <Title level={3}>Most Traded Symbol <DescriptionIcon description={statisticsSymbol.mostTraded} /></Title>
                  <p>Symbol</p>
                  <Tag color={'purple'} key={maxTradesPerSymbol?.symbol}>
                      {maxTradesPerSymbol?.symbol}
                  </Tag>
                  <p>Trades</p>
                  <Statistic
                      value={maxTradesPerSymbol?.trades}
                      precision={0}
                    />
              </Card>
          </Col>
          <Col span={8}>
              <Card>
                  <Title level={3}>Highest Traded Volume Symbol <DescriptionIcon description={statisticsSymbol.highestTradedVolume} /></Title>
                  <p>Symbol</p>
                  <Tag color={'purple'} key={maxTradesByVolumePerSymbol?.symbol}>
                      {maxTradesByVolumePerSymbol?.symbol}
                  </Tag>
                  <p>Trades</p>
                  <Statistic
                      value={maxTradesByVolumePerSymbol?.volume}
                      precision={0}
                    />
              </Card>
          </Col>
          <Col span={8}>
              <Card>
                  <Title level={3}>Most Winning Trades Symbol <DescriptionIcon description={statisticsSymbol.mostWinningTrade} /></Title>
                  <p>Symbol</p>
                  <Tag color={'purple'} key={mostProfitableByTrades?.symbol}>
                      {mostProfitableByTrades?.symbol}
                  </Tag>
                  <p>Trades</p>
                  <Statistic
                      value={mostProfitableByTrades?.wins}
                      precision={0}
                    />
              </Card>
          </Col>
          <Col span={8}>
              <Card>
                  <Title level={3}>Most Losing Trades Symbol <DescriptionIcon description={statisticsSymbol.mostLosingTrade} /></Title>
                  <p>Symbol</p>
                  <Tag color={'purple'} key={leastProfitableByTrades?.symbol}>
                      {leastProfitableByTrades?.symbol}
                  </Tag>
                  <p>Trades</p>
                  <Statistic
                      value={leastProfitableByTrades?.losses}
                      precision={0}
                    />
              </Card>
          </Col>
          <Col span={8}>
              <Card>
                  <Title level={3}>Most Profitable Symbol <DescriptionIcon description={statisticsSymbol.mostProfitable}/></Title>
                  <p>Symbol</p>
                  <Tag color={'purple'} key={mostProfitableByPnL?.symbol}>
                      {mostProfitableByPnL?.symbol}
                  </Tag>
                  <p>P&L</p>
                  <Statistic
                      value={mostProfitableByPnL?.PnL}
                      precision={2}
                      valueStyle={mostProfitableByPnLColor}
                    />
              </Card>
          </Col>
          <Col span={8}>
              <Card>
                  <Title level={3}>Least Profitable Symbol <DescriptionIcon description={statisticsSymbol.leastProfitable} /></Title>
                  <p>Symbol</p>
                  <Tag color={'purple'} key={leastProfitableByPnL?.symbol}>
                      {leastProfitableByPnL?.symbol}
                  </Tag>
                  <p>P&L</p>
                  <Statistic
                      value={leastProfitableByPnL?.PnL}
                      precision={2}
                      valueStyle={leastProfitableByPnLColor}
                    />
              </Card>
          </Col>
      </Row>
  );

};

SymbolStatistics.propTypes = {

  mostProfitableByTrades: PropTypes.object,
  leastProfitableByTrades: PropTypes.object,
  mostProfitableByPnL: PropTypes.object,
  leastProfitableByPnL: PropTypes.object,
  maxTradesPerSymbol: PropTypes.object,
  maxTradesByVolumePerSymbol: PropTypes.object

};

export default SymbolStatistics;

/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Alert, Button, Input, message, Modal, Select, Skeleton, Space, Tag } from 'antd';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { CloseOutlined } from '@ant-design/icons';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import axios from '../../../utilities/axios';
import setTextColor from '../../../utilities/brightnessTextColor';

import './styles.scss';

const fetchCategoryTags = (token) => axios.get('crud/tagCategory', {
  headers: {
    token
  }
}).then(({ data }) => data);

export default function TradeTags({ tradeId, initialTags, token, tradeStatus }) {

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [searchText, setSearchText] = useState('');
  const [categories, setCategory] = useState([]);
  const [tags, setTags] = useState(initialTags);
  const [categoryLabel, setCategoryLabel] = useState('');
  const [categoryModalVisible, setCategoryModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectByArrowKey, setSelectByarrowKey] = useState(false);
  const defaultCategoryNames = [
    'confirmation',
    'indicators',
    'profit set',
    'strategy set',
    'trade category'];

  const { data: tagCategories = [], isLoading } = useQuery(['tradeTagCategory'], () => fetchCategoryTags(token));

  useEffect(() => {

    if (tagCategories && tagCategories.length > 0) setCategory(tagCategories);

  }, [tagCategories]);

  const handleSearch = (value) => {

    setSearchText(value);

  };

  const handleUpdateTag = async (key, id, label, categoryId) => {

    if (key === 'Enter' && loading === false) {

      const tagExists = tags.filter((x) => x.label === label.trim() && x.categoryId === categoryId);

      if (tagExists && tagExists.length > 0) {

        return;

      }

      if (label.trim() === '' || categoryId.trim() === '') {

        return;

      }

      setLoading(true);

      await axios.post(`crud/trade/${tradeId}/tag`, {
        tagID: id,
        label: label.trim(),
        categoryId
      }, { headers: { token } })
        .then((response) => {

          if (response.status === 200) {

            const newTag = response.data.tags[response.data.tags.length - 1];

            const existTags = tags.filter((x) => x._id === newTag._id);
            if (existTags.length <= 0) {

              setTags((prevTags) => [...prevTags, newTag]);
              const updatedCategories = categories.map((category) => {

                if (category._id === categoryId) {

                  return { ...category, tags: [...category.tags, newTag] };

                }
                return category;

              });

              setCategory(updatedCategories);

            }

            queryClient.invalidateQueries(['trade-individual']);
            queryClient.invalidateQueries(['tags']);
            queryClient.invalidateQueries(['tradeTagCategory']);

          } else {

            message.error(response.error);

          }

        })
        .catch(() => {

          message.error('Something went wrong!');

          setSearchText('');

        }).finally(() => {

          setLoading(false);
          setSearchText('');
          setSelectByarrowKey(false);

        });

    }

  };

  const removeTagFromTrade = (tagID) => {

    axios.delete(`crud/trade/${tradeId}/tag/${tagID}`)
      .then((response) => {

        if (response.status === 200) {

          setTags((prevTags) => prevTags.filter((tag) => tag._id !== tagID));
          queryClient.invalidateQueries(['trade-individual']);
          queryClient.invalidateQueries(['tags']);
          queryClient.invalidateQueries(['tradeTagCategory']);

        } else {

          message.error(response.error);

        }

      })
      .catch((ex) => {

        message.error('Something went wrong!', ex);

      });

  };

  const handleCreateCategory = () => {

    if (categoryLabel.trim() === '') {

      message.error('Please input your category name!');
      return;

    }

    if (defaultCategoryNames.filter((x) => x === categoryLabel.trim().toLowerCase()).length > 0) {

      message.error('The category already exists.');
      return;

    }

    axios.post('/crud/tagCategory', {
      label: categoryLabel,
      color: '#F4AA41' // this is default color
    }, {
      headers: {
        token
      },
      timeout: 60000 * 5
    }).then((response) => {

      if (response && response.status === 201) {

        message.success('Category name was created successfully.');
        setCategory((prev) => [...prev, { ...response.data, tags: [] }]);

      }

    })
      .catch((err) => {

        if (err.response && err.response.data && err.response.data.message) {

          message.error(err.response.data.message);

        } else {

          message.error('Something went wrong. Please try again later.');

        }

      }).finally(() => {

        setCategoryLabel('');
        setCategoryModalVisible(false);

      });

  };

  const handleTagRender = (prop) => {

    if (prop.value) {

      const tag = tags.find((x) => x._id === prop.value);
      const category = tagCategories.find((x) => x._id === tag.categoryId);

      return (
          <Tag
              color='#108ee9'
              closable={prop.closable}
              onClose={prop.onClose}
              style={{
                color: setTextColor(category?.color || '#F4AA41'),
                backgroundColor: category?.color || '#F4AA41',
                borderRadius: '10px',
                marginRight: 3,
                display: 'inline-flex',
                alignItems: 'center',
                cursor: 'pointer',
                transition: 'all 0.3s',
                padding: '1px 5px'
              }}
              closeIcon={
                  <CloseOutlined
                      style={{
                        color: setTextColor(category?.color || '#F4AA41'),
                        fontSize: '12px',
                        marginLeft: '8px',
                        opacity: 0.6
                      }}
                      onMouseEnter={(e) => {

                        e.currentTarget.style.opacity = '1';

                      }}
                      onMouseLeave={(e) => {

                        e.currentTarget.style.opacity = '0.6';

                      }}
            />
          }
        >
              {prop.label}
          </Tag>
      );

    }

    return null;

  };

  return (
      <div>
          {isLoading === true ?
              <Space>
                  <Skeleton.Input size='small' active />
                  <Skeleton.Input size='small' active />
                  <br />
                  <Skeleton.Input size='small' active />
                  <Skeleton.Input size='small' active />
                  <br />
                  <Skeleton.Input size='small' active />
                  <Skeleton.Input size='small' active />
                  <br />
                  <Skeleton.Input size='small' active />
                  <Skeleton.Input size='small' active />
                  <br />
                  <Skeleton.Input size='small' active />
                  <Skeleton.Input size='small' active />
              </Space>
            : <>
                {tradeStatus !== 'closed' &&
                <Alert
                    showIcon
                    message='Tagging is available for closed trades only.'
                    description={'Once trades are closed, you\'ll have the option to add tags for easier categorization.'}
                    type='warning'
                    className='tagBoxWarning'
            />
          }
                <div className='categories'>
                    <div className='tags'>
                        {categories.map((item) => {

                          const filterTag = tags.filter((x) => x.categoryId === item._id && x.label.trim() !== '');

                          return filterTag.map((tag) => (
                              <span
                                  style={{ backgroundColor: item.color || '#F4AA41', color: setTextColor(item.color || '#F4AA41') }}
                                  key={tag._id}
                  >
                                  {tag.label}
                              </span>
                          ));

                        })}
                    </div>
                    {
              categories?.map((item, index) => <div className='categoryItem' key={index}>
                  <span className='bgColor' style={{ backgroundColor: item.color || '#F4AA41' }}></span>
                  <label>{item.label}</label>
                  <Select
                      mode='multiple'
                      style={{ width: '100%' }}
                      placeholder='select a tag'
                      searchValue={searchText}
                      onSearch={handleSearch}
                      onInputKeyDown={(e) => {

                        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {

                          setSelectByarrowKey(true);
                          e.preventDefault();

                        } else if (selectByArrowKey === false) {

                          handleUpdateTag(e.key, '', searchText, item._id);

                        } else {

                          setSelectByarrowKey(false);

                        }

                      }}
                      onBlur={() => {

                        setSearchText('');
                        setSelectByarrowKey(false);

                      }}
                      disabled={tradeStatus !== 'closed'}
                      onSelect={async (e) => {

                        const option = item.tags.filter((x) => x._id === e)[0];

                        await handleUpdateTag('Enter', option._id, option.label, item._id);

                      }}
                      value={tags.filter((x) => x.categoryId === item._id && x.label.trim() !== '').map((x) => ({
                        label: x.label,
                        value: x._id
                      }))}
                      options={item.tags
                        .filter((x) => x.label.trim() !== '' && x.status === true)
                        .reduce((acc, current) => {

                          const t = acc.find((x) => x.label === current.label);
                          if (!t) {

                            return acc.concat({ label: current.label, value: current._id });

                          }
                          return acc;

                        }, [])}
                      tagRender={handleTagRender}
                      onDeselect={(e) => removeTagFromTrade(e)}
                      defaultActiveFirstOption={false}
                      filterOption={(input, element) => element.label.toLowerCase().includes(input.toLowerCase())
                  }
                />
              </div>)
            }

                    {tradeStatus === 'closed' &&
                    <div className='tagCategoryManagement'>
                        <Button type='link' onClick={() => {

                          setCategoryModalVisible(true);
                          setCategoryLabel('');

                        }}>Add new category</Button>
                        <Button type='link' onClick={() => navigate('/settings/3')}>Manage</Button>
                    </div>
            }

                </div>

                <Modal
                    open={categoryModalVisible}
                    title='New Category'
                    okText='Create'
                    onOk={handleCreateCategory}
                    onCancel={() => setCategoryModalVisible(false)}
                    className='categoryModal'
          >
                    <label>Category Name</label>
                    <Input value={categoryLabel} placeholder='Favourite Stocks' onChange={(e) => setCategoryLabel(e.target.value)} />

                </Modal>

            </>}
      </div>
  );

}

TradeTags.propTypes = {
  initialTags: PropTypes.array,
  token: PropTypes.string,
  tradeId: PropTypes.string,
  tradeStatus: PropTypes.string
};

import React from 'react';
import PropTypes from 'prop-types';

import { ResponsiveBar } from '@nivo/bar';

const DailyWinLossBarChart = ({ data }) => (
    <ResponsiveBar
        data={data}
        keys={[
          'totalWinsPerDay',
          'totalLossesPerDay'
        ]}
        indexBy='date'
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        padding={0.9}
        borderRadius={5}
        tooltipLabel={(p) => <span>{`${p.id === 'totalWinsPerDay' ? 'Daily Win' : 'Daily Loss'} - ${p.data.date}`}</span> }
        theme={{ tooltip: {
          container: {
            backgroundColor: '#263238D9',
            color: '#e0e3eb',
            fontWeight: 400,
            borderRadius: '0.5rem',
            padding: '0.5rem',
            lineHeight: 'normal',
            fontSize: '0.75rem'
          }
        } }}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={['#29a373', '#E57373']}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Win/Loss',
          legendPosition: 'middle',
          legendOffset: -40
        }}
        enableLabel={false}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
    />
);

DailyWinLossBarChart.propTypes = {

  data: PropTypes.array

};

export default DailyWinLossBarChart;

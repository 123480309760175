/* eslint-disable no-nested-ternary */
import { React, useState } from 'react';
import { Avatar, Button, Col, Dropdown, Select, Space, Tooltip, Typography } from 'antd';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import PersonImg from '../../../../resources/images/person.svg';
import { useAccountStore, useImportDrawerStore, useManagementStore, useUserState } from '../../../../store';
import axios from '../../../../utilities/axios';
import { getCookie } from '../../../../utilities/cookie';
import AIDrawer from '../ai-drawer';
import ImportDrawer from '../import-drawer';

import './styles.scss';

const { Text } = Typography;

export default function Header() {

  const [AIDrawerOpen, setAIDrawerOpen] = useState(false);
  const { allAccounts, setSelectedAccount } = useAccountStore((state) => state);
  const { viewAs, setViewAs, allUsers, setAllUsers } = useManagementStore((state) => state);
  const { getUser } = useUserState();
  const user = getUser();
  const { resetUser } = useUserState();
  const { importDrawerOpen, setImportDrawerOpen } = useImportDrawerStore();
  const { pathname } = useLocation();
  const isDashboard = pathname === '/';
  const isSettingsRoute = pathname.startsWith('/settings');

  const navigate = useNavigate();
  const userFromCookie = JSON.parse(getCookie('user'));

  const isViewAsActive = JSON.stringify(viewAs?.newUser?.token || null) !== JSON.stringify(viewAs?.mainUser?.token || null);

  const redirectToAddTrade = () => {

    // Only pre-select account if user has more than a Live Trade account
    // This is only needed for Admins, since the Import Trade button is already hidden for normal users if they only have Live Trade account, but admins still need the button
    // This avoids a crash since no account existed for upload purposes
    if (allAccounts.filter((x) => x.label !== 'Live Trade')?.length > 0) {

      setSelectedAccount(allAccounts.filter((x) => x.label !== 'Live Trade')[0]);

    }

    navigate('/add-trade');

  };
  const onImportClose = () => setImportDrawerOpen(false);

  //   const showAIDrawer = () => setAIDrawerOpen(true);
  const onAIClose = () => setAIDrawerOpen(false);

  const handleLogout = () => {

    // logout();
    resetUser();
    navigate(0);
    // window.location.replace('/');

  };

  // token needed is admin user only, no need to pass viewAs token
  const fetchUsers = () => axios.get('crud/users', { headers: { token: user?.token } }).then(({ data }) => data);

  useQuery(['users'], () => fetchUsers().then(async (users) => {

    try {

      if (users.length > 0) {

        const userSelectionFormat = users?.map((u) => {

          // Show only non-admin and users with PCT IDs added
          if (u.role !== 'admin') {

            return {
              value: u.id,
              label: u.pct_id && u.pct_id !== '' ? u.pct_id : u.name,
              token: u.token
            };

          }

          return null;

        }).filter(Boolean) || [];

        // Adding my own account to the top of the list

        const currentAdmin = users.find((x) => x.id === user.id);

        userSelectionFormat.unshift({
          value: user?.id,
          label: `${currentAdmin && currentAdmin.pct_id && currentAdmin.pct_id !== '' ? currentAdmin.pct_id : user?.name} (Me)`,
          token: user?.token
        });

        setAllUsers(userSelectionFormat);

      } else {

        setAllUsers([]);

      }

      return users;

    } catch (error) {

      return [];

    }

  }), { enabled: user.token !== undefined && user.role === 'admin' });

  const onChange = (newId) => {

    const newToken = allUsers.find((u) => u.value === newId)?.token;
    setViewAs({ token: newToken, id: newId }, { token: user?.token, id: user?.id });

    if (!isDashboard) navigate('/');

  };

  const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const basicMeasureOptions = [
    {
      key: 'Settings',
      label: 'Settings',
      icon: <span className='tt-person' style={{ fontSize: 16 }} />,
      onClick: () => navigate('settings/1')
    },
    {
      key: 'Logout',
      label: <span style={{ color: '#e57373' }}>Logout</span>,
      icon: <span className='tt-logout' style={{ fontSize: 16, color: '#e57373' }} />,
      onClick: () => handleLogout()
    }
  ];

  const renderContent = () => {

    if (!isViewAsActive) {

      if (allAccounts.length > 0) {

        return (
            <>
                <Col span={8}>
                    <div className='align-start'>
                        {/* Showing Import Trade button only if user has other accounts than Live Trade or if he is an admin that needs to upload trades for other users */}
                        {(allAccounts.filter((x) => x.label !== 'Live Trade')?.length > 0 || user.role === 'admin') && <Tooltip title='Import Trade Logs' key='importButton'>
                            <Button
                                icon={<span className='tt-plus' style={{ fontSize: 12 }} />}
                                type='primary'
                                onClick={redirectToAddTrade}
                  >
                                Import Trades
                            </Button>
                        </Tooltip>}

                    </div>
                </Col>
                <Col span={8} offset={8}>
                    <Space size={24} className='align-end'>
                        {user.role === 'admin' && !isSettingsRoute && (
                        <Space size={6}>
                            <span>View As:</span>
                            <Select
                                showSearch
                                style={{ width: 180 }}
                                placeholder='Select a user'
                                maxTagCount={1}
                                value={viewAs?.newUser?.id.length && allUsers.find((u) => u.value === viewAs?.newUser?.id) ? viewAs.newUser.id : allUsers[0]?.value}
                                onChange={onChange}
                                filterOption={filterOption}
                                options={allUsers}
                    />
                        </Space>
                        )}
                        <Dropdown
                            menu={{
                              items: basicMeasureOptions,
                              selectable: false
                            }}
                >
                            <div className='user-dropdown-button' onClick={(e) => e.preventDefault()}>
                                <Avatar
                                    className='user-avatar'
                                    size={32}
                                    src={userFromCookie?.avatar || PersonImg}
                    />
                                <Text strong className='user-name-text' style={{ padding: 5 }}>
                                    Hi, {userFromCookie?.name || ''}
                                </Text>
                                <span className={`down-icon tt-Chevron_down ${userFromCookie?.darkMode ? 'dark' : ''}`} />
                            </div>
                        </Dropdown>
                    </Space>
                </Col>
            </>
        );

      } if (isSettingsRoute) {

        return <Col span={24} style={{ height: '2rem' }} />;

      }

      return (
          <Col span={24}>
              <Space className='align-end'>
                  <Button type='primary' onClick={() => navigate('settings/1')}>
                      New Trading Account
                  </Button>
              </Space>
          </Col>
      );

    }

    return (
        <Col span={8} offset={16}>
            <Space size={24} className='align-end'>
                <Space size={6}>
                    <span>View As:</span>
                    <Select
                        showSearch
                        style={{ width: 180 }}
                        placeholder='Select a user'
                        maxTagCount={1}
                        value={viewAs?.newUser?.id.length ? viewAs.newUser.id : allUsers[0]?.value}
                        onChange={onChange}
                        filterOption={filterOption}
                        options={allUsers}
            />

                </Space>
                <Dropdown
                    menu={{
                      items: basicMeasureOptions,
                      selectable: false
                    }}
          >
                    <div className='user-dropdown-button' onClick={(e) => e.preventDefault()}>
                        <Avatar
                            className='user-avatar'
                            size={32}
                            src={userFromCookie?.avatar || PersonImg}
              />
                        <Text strong className='user-name-text' style={{ padding: 5 }}>
                            Hi, {userFromCookie?.name || ''}
                        </Text>
                        <span className={`down-icon tt-Chevron_down ${userFromCookie?.darkMode ? 'dark' : ''}`} />
                    </div>
                </Dropdown>
            </Space>
        </Col>
    );

  };

  return (
      <>
          <div className='header-container'>
              {renderContent()}
              <ImportDrawer open={importDrawerOpen} onClose={onImportClose} />
              <AIDrawer open={AIDrawerOpen} onClose={onAIClose} />
          </div>
      </>
  );

}

/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { Avatar, Button, Card, Carousel, Col, Empty, Image, message, Modal, Popconfirm, Row, Upload } from 'antd';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';

import { EyeOutlined, PictureOutlined } from '@ant-design/icons';

import NotesEditor from '../../../components/notes-editor';
import PersonImg from '../../../resources/images/person.svg';
import { useUserState } from '../../../store';
import axios from '../../../utilities/axios';
import getTimeDifference from '../../../utilities/relativeTime';

export default function TradeNotes({ tradeNotes, setTradeNotes }) {

  const allowedFileTypes = ['image/png', 'image/jpeg', 'image/gif'];
  const [notesContent, setNotesContent] = useState('');
  const [noteId, setNoteId] = useState('');
  const [imageModalVisible, setImageModalVisible] = useState(false);
  const [imageModalItems, setImageModalItems] = useState([]);
  const [imageSelectedIndex, setImageSelectedIndex] = useState(0);

  const [fileList, setFileList] = useState([]);

  const { id } = useParams();
  const { getUser } = useUserState();
  const user = getUser();

  const addOrUpdateNotesToTrade = (notes) => {

    const formData = new FormData();
    formData.append('id', notes.id);
    formData.append('content', notes.content);
    formData.append('userId', notes.userId);
    formData.append('date', notes.date);

    fileList.forEach((file) => {

      formData.append('files', file.originFileObj);

    });

    axios
      .put(`crud/trades/notes/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(({ data }) => {

        const updatedNotes = [...tradeNotes];
        const existingNoteIndex = updatedNotes.findIndex((note) => note._id === notes.id);

        if (existingNoteIndex !== -1) {

          updatedNotes[existingNoteIndex].content = notes.content;
          const noteSelected = data.notes.find((note) => note._id === notes.id);
          updatedNotes[existingNoteIndex].images = noteSelected.images;
          setTradeNotes((x) => (x === notes.id ? { ...x, content: notes.content } : x));

        } else {

          const newNote = {
            _id: data.notes[data.notes.length - 1]._id,
            content: notes.content,
            createDate: notes.date,
            userId: {
              first_name: user.name,
              last_name: user.lastName,
              image: user.avatar,
              _id: user.id
            },
            images: data.notes[data.notes.length - 1].images
          };

          updatedNotes.push(newNote);
          setTradeNotes([...tradeNotes, newNote]);

        }

        setNotesContent('');
        setTradeNotes(updatedNotes);
        setFileList([]);
        message.success('Notes saved!');

      }).catch((ex) => {

        const errorMessage = ex?.response?.data?.error || '';

        setNoteId('');
        setNotesContent('');
        setFileList([]);
        message.error(errorMessage || 'Failed to save notes.', 3);

      });

  };

  const deleteTradeNote = async (_noteid) => {

    try {

      await axios.delete(`crud/trades/notes/${id}/${_noteid}`);
      setTradeNotes((prevNotes) => prevNotes.filter((note) => note._id !== _noteid));

      message.success('Note removed!');

    } catch (error) {

      message.error('Failed to delete trade note.');

    }

  };

  const deleteTradeNoteImage = async (_noteid, _imageid) => {

    try {

      await axios.delete(`crud/trades/notes/image/${id}/${_noteid}/${_imageid}`);

      setTradeNotes((prevNotes) => prevNotes.map((note) => (note._id === _noteid
        ? { ...note, images: note.images.filter((image) => image._id !== _imageid) }
        : note)));

      message.success('Image removed!');

    } catch (error) {

      message.error('Failed to delete image.');

    }

  };

  const save = () => {

    if (notesContent.length > 0) {

      const newNote = {
        id: noteId,
        content: notesContent,
        userId: user.id,
        date: new Date().toISOString()
      };

      addOrUpdateNotesToTrade(newNote);
      setNotesContent('');
      setNoteId('');

    }

  };

  const handleEdit = (record) => {

    setNotesContent(record.content);
    setNoteId(record._id);

    const newFileList = record.images.map((image, index) => ({
      uid: index,
      name: `image-${index}`,
      status: 'done',
      url: image.path,
      id: image._id,
      noteId: record._id
    }));

    setFileList(newFileList);

  };

  const handleChange = ({ fileList: newFileList }) => {

    const hasInvalidFile = newFileList.some((file) => !file.id && !allowedFileTypes.includes(file.type));

    if (hasInvalidFile) {

      message.error('You can only upload PNG, JPG, and GIF files!');
      const validFiles = newFileList.filter((file) => allowedFileTypes.includes(file.type));
      setFileList(validFiles);

    } else {

      setFileList(newFileList);

    }

  };

  const handlePreview = (images, imageIndex) => {

    setImageModalVisible(!imageModalVisible);
    setImageModalItems(images);
    setImageSelectedIndex(imageIndex);

  };

  return (
      <Col span={24}>
          <Card
              className='cardTradeNotes'
              title='Trade Notes'
              bordered={true} >
              {!tradeNotes || tradeNotes.length <= 0 ? (
                  <Empty description={<p>No notes.</p>} />

              ) : (

                  <div>
                      {tradeNotes
                        .filter((x) => x.content !== '')
                        .slice()
                        .reverse()
                        .map((record, key) => <div className='noteItem' key={key}>
                            <h3>
                                <Avatar
                                    className='user-avatar'
                                    size={32}
                                    src={record.userId?.image || PersonImg} />
                                {record.userId ? `${record.userId.first_name} ${record.userId.last_name}` : 'Unknown'}

                                <span className='time'>
                                    {(record.createDate ? getTimeDifference(record.createDate) : '')}
                                </span>
                                <span className='tools'>
                                    {record.userId?._id === user.id && (
                                    <>
                                        <Button type='link' onClick={() => handleEdit(record)} href='#editoSection'>
                                            Edit</Button>
                                        <Popconfirm
                                            title='Remove Note?'
                                            description='This will delete note in this trade. Are you sure?'
                                            onConfirm={() => deleteTradeNote(record._id)}
                                            okText='Yes'
                                            cancelText='No'
                                            placement='top' >

                                            <Button type='link'>Delete</Button>

                                        </Popconfirm>

                                    </>
                                    )}
                                </span>
                            </h3>
                            <p>
                                {record.content}
                            </p>
                            {record.images && record.images.length > 0 &&
                            <div className='noteImages'>
                                {record.images && record.images.length > 0 && (
                                <>
                                    {record.images.map((img, imgKey) => (
                                        <div className='image' key={imgKey}>
                                            <Image
                                                alt={record.content}
                                                src={img.path}
                                                height={80}
                                                preview={{
                                                  visible: false,
                                                  onVisibleChange: () => {

                                                    handlePreview(record.images, imgKey);

                                                  },
                                                  mask: (
                                                      <div className='imageTools' >
                                                          <Button type='link' ><EyeOutlined /></Button>
                                                      </div>
                                                  )
                                                }} />
                                        </div>
                                    ))}
                                </>
                                )}
                            </div>

                }
                        </div>)

            }
                  </div>
              )}

              <div className='cardFooter' id='editoSection'>
                  <h2 >{noteId !== '' ? 'Edit' : 'Add'} Note</h2>
                  <Row style={{ width: '100%', gap: 10, marginBottom: '20px' }} justify='center'>
                      <Col span={24}>
                          <NotesEditor onChange={setNotesContent} content={notesContent} />
                      </Col>
                  </Row>
                  <div className='buttonBox'>
                      <Upload
                          listType='picture-card'
                          fileList={fileList}
                          onChange={handleChange}
                          onRemove={(file) => {

                            if (file.id && file.id !== '') {

                              deleteTradeNoteImage(file.noteId, file.id);

                            }
                            setFileList(fileList.filter((f) => f.uid !== file.uid));

                          }}
                          beforeUpload={() => false} >
                          {fileList.length >= 3 ? null : (
                              <div>
                                  <PictureOutlined />
                                  <div style={{ marginTop: 8 }}>Upload</div>
                              </div>
                          )}
                      </Upload>
                      <div>
                          <Button type='primary' disabled={!notesContent || notesContent.length <= 0} onClick={save}>{noteId !== '' ? 'Update' : 'Submit'}</Button>
                          {noteId !== '' &&
                          <Button onClick={() => {

                            setNoteId('');
                            setNotesContent('');
                            setFileList([]);

                          }} >Cancel</Button>}

                      </div>
                  </div>
              </div>
          </Card>

          {imageModalItems.length > 0 &&
          <Modal
              title={imageModalItems.length > 0 ? imageModalItems[0].selectedImage : 'reza'}
              className='ImageModal'
              open={imageModalVisible}
              onCancel={() => {

                setImageModalVisible(false);
                setImageModalItems([]);

              }
          }
              footer={null}>

              <Carousel
                  arrows={true}
                  dots={true}
                  infinite={true}
                  autoplay={false}
                  draggable={true}
                  initialSlide={imageSelectedIndex}
                  style={{ width: '100%', minHeight: 200 }} >
                  {imageModalItems.map((img, imgKey) => (
                      <div key={imgKey} className='imageBox'>
                          <Image
                              preview={false}
                              alt='Note Image'
                              src={img.path}
                              style={{ objectFit: 'contain', height: '100%', width: '100%' }}
                />
                      </div>
                  ))}
              </Carousel>

          </Modal>}
      </Col>
  );

}

TradeNotes.propTypes = {
  tradeId: PropTypes.string,
  tradeNotes: PropTypes.array,
  trade: PropTypes.object,
  isViewAsActive: PropTypes.bool,
  setTradeNotes: PropTypes.any
};

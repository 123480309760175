import { React, useState } from 'react';
import { Button, Drawer, Form, Input, Space } from 'antd';
import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';

import { useQueryClient } from '@tanstack/react-query';

import { useSettingStore } from '../../../../store';
import axios from '../../../../utilities/axios';

import './styles.scss';

const { TextArea } = Input;
export default function AIDrawer({ open, onClose }) {

  const queryClient = useQueryClient();
  const { filter } = useSettingStore((state) => state);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);

  // const storeTrades = (file, date, broker, brokerTimezone, accountID, method, advancedSettings) => axios.get(
  //     `http://localhost:5000/trades/executions-to-trades?file=${file}&date=${date}&broker=${broker}&brokerTimezone=${brokerTimezone}&accountID=${accountID}&method=${method}&advancedSettings=${advancedSettings}`
  // ).then(() => queryClient.refetchQueries('trades'));
  const summaryData = queryClient.getQueryData(['summary-account', filter]);

  const onFinish = async (values) => {

    setLoading(true);

    await axios
      .post(
        'api/ai-query',
        { ...values, data: summaryData },
        {
          timeout: 60000 // need larger timeout interval
        }
      )
      .then(({ data }) => {

        setMessages((state) => [{ question: values.query, answer: [...new Set([...data.message])] }, ...state]);

      })
      .catch(({ response }) => {

        if (response && response.data && response.data.message) {

          setMessages((state) => [{ question: values.query, answer: [response.data.message] }, ...state]);

        } else {

          setMessages((state) => [{ question: values.query, answer: ['Error, please try again.'] }, ...state]);

        }

      })
      .finally(() => setLoading(false));

  };

  return (
      <>
          <Drawer title='Get insights with our AI Q&A' placement={'right'} closable={false} onClose={onClose} open={open} key={'right'}>
              <Space size={[0, 8]} direction='vertical' style={{ width: '100%' }}>
                  <Form
                      name='ai-query'
                      wrapperCol={{
                        span: 24
                      }}
                      onFinish={onFinish}
                      autoComplete='off'
          >
                      <Form.Item
                          name='query'
                          rules={[
                            {
                              required: true,
                              message: 'Please write a question.'
                            }
                          ]}
            >
                          <TextArea
                              placeholder='e.g. What is my biggest win trade?'
                              autoSize={{
                                minRows: 2,
                                maxRows: 5
                              }}
                              maxLength={180}
              />
                      </Form.Item>
                      <Form.Item>
                          <Button type='primary' htmlType='submit'>
                              Send
                          </Button>
                      </Form.Item>{' '}
                  </Form>
                  {loading && <span>Loading...</span>}
                  {messages &&
            Array.isArray(messages) &&
            messages.length > 0 &&
            messages.map((message, index) => (
                <Space key={index} size={[0, 18]} direction='vertical' style={{ width: '100%', textAlign: 'justify', lineHeight: '1.35rem' }}>
                    <div className='wrap-question'>
                        <strong>Q: {message.question}</strong>
                    </div>

                    {message.answer.map((answer, i) => (
                      answer ? <span key={i} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(answer.replaceAll('\n', '</br>').replace(/【\d+†source】/g, '')) }}></span> : <></>
                    ))}
                </Space>
            ))}
              </Space>
          </Drawer>
      </>
  );

}

AIDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

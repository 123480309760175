import React, { useState } from 'react';
import { Button, Dropdown, Input, Menu, message, Modal, Select, Table, Typography } from 'antd';
import PropTypes from 'prop-types';

import { DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import showDeleteConfirm from '../../../../components/Confirmation-modal';
import { useSettingStore, useUserState } from '../../../../store';
import axios from '../../../../utilities/axios';

const fetchTags = (token) => axios.get('crud/tag', {
  headers: {
    token
  }
}).then(({ data }) => data.filter((x) => x.status !== false));

const fetchCategoryTags = (token) => axios.get('crud/tagCategory', {
  headers: {
    token
  }
}).then(({ data }) => data);

export default function TagComponent({ setOpenControllModal, openControllModal }) {

  const queryClient = useQueryClient();

  const { getUser } = useUserState();
  const user = getUser();

  const { filter, changeFilter } = useSettingStore((state) => state);

  const { Text } = Typography;
  const [tagSelected, setTagSelected] = useState({});
  const [submited, setSubmited] = useState(false);

  const { data: tags = [], isLoading } = useQuery(['tags'], () => fetchTags(user?.token));
  const { data: tagCategories = [] } = useQuery(['tradeTagCategory'], () => fetchCategoryTags(user?.token));

  const closeModal = () => {

    setSubmited(false);
    setOpenControllModal(false);
    setTagSelected({});

  };

  const tagCreateorUpdate = () => {

    setSubmited(true);

    const { _id: tagID, categoryId, label, description } = tagSelected;

    if (label && label.trim().length > 0 && categoryId && categoryId.length > 0) {

      const body = {
        tagID,
        categoryId,
        label: label.trim(),
        description
      };

      axios.post('crud/tag', body, {
        headers: {
          token: user?.token
        }
      }).then(() => {

        message.success(!tagID ? 'New tag created' : 'Tag updated');
        queryClient.invalidateQueries(['trade-individual']);
        queryClient.invalidateQueries(['tags']);
        queryClient.invalidateQueries(['tradeTagCategory']);
        closeModal();

      }).catch((error) => {

        message.error(error.response.data.message || 'Oops! Something Went Wrong. Please try again.');

      });

    }

  };

  const tagDelete = (isConfirmed, id) => {

    if (isConfirmed) {

      const tagSelectedRemove = tags.filter((x) => x._id === id)[0];

      axios.delete(`crud/tag/${id}`, {
        headers: {
          token: user?.token
        }
      }).then(() => {

        message.success('Tag changes saved');
        queryClient.invalidateQueries(['trade-individual']);
        queryClient.invalidateQueries(['tags']);
        queryClient.invalidateQueries(['tradeTagCategory']);

        changeFilter('tags', filter.tags.filter((x) => x !== tagSelectedRemove._id));

      }).catch((error) => {

        message.error(error.response.data.message || 'Oops! Something Went Wrong. Please try again.');

      });

    }

  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'label',
      key: 'label',
      width: 150
    },
    {
      title: 'Category',
      key: 'categoryId',
      render: (record) => <div className='category'>
          <span className='categoryColor smallColor' style={{ backgroundColor: record.categoryColor }}>
          </span>
          {record.categoryLabel}
      </div>
    }, {
      title: 'Used',
      dataIndex: 'tradeCount',
      key: 'tradeCount',
      width: 150
    }, {
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    }, {
      title: 'Action',
      key: 'operation',
      fixed: 'right',
      width: 100,
      render: (record) => <Dropdown trigger='click'
          overlay={
              <Menu>
                  <Menu.Item
                      key='1'
                      onClick={() => {

                        setOpenControllModal(true);
                        setTagSelected(record);

                      }}
            >
                      <Text>
                          <i className='tt-edit' /> Edit
                      </Text>
                  </Menu.Item>
                  <Menu.Item
                      key='2'
                      onClick={() => showDeleteConfirm(
                        (isConfirmed) => tagDelete(isConfirmed, record._id),
                        record.label,
                        record.tradeCount <= 0 ?
                          `Are you sure to delete "${record.label}" tag?` :
                          'This tag is currently assigned to one or more trades. Deleting it will remove the tag from all associated trades. Are you sure you want to proceed?'
                      )}
            >
                      <Text type='danger'>
                          <DeleteOutlined /> Delete...
                      </Text>
                  </Menu.Item>
              </Menu>
        }
          placement='bottomLeft' arrow>
          <Button type='text' icon={<MoreOutlined />} />
      </Dropdown>
    }
  ];

  return (
      <div className='tagIndivisualManagement'>

          {isLoading === false && <Table
              columns={columns}
              dataSource={tags}
              pagination={false}
              rowKey='key'
      />}

          <Modal
              className='tagModal borderModal'
              title={tagSelected._id ? 'Edit Tag' : 'New Tag'}
              open={openControllModal}
              onCancel={closeModal}
              footer={<>
                  <Button onClick={closeModal}>Cancel</Button>
                  <Button type='primary' onClick={tagCreateorUpdate}>{tagSelected._id ? 'Save' : 'Create'}</Button>
              </>
        }>
              <div className='controllerItem'>
                  <label>Tag Name</label>
                  <Input
                      value={tagSelected.label || ''}
                      className={submited === true && (!tagSelected.label || tagSelected.label.trim() === '') ? 'ant-input-status-error' : ''}
                      onChange={(e) => {

                        setTagSelected({
                          ...tagSelected,
                          label: e.target.value
                        });

                      }}
                      placeholder='Name your tag'
          />
                  {submited === true && (!tagSelected.label || tagSelected.label.trim() === '') &&
                  <div>
                      <div id='username_help' className=' ant-form-item-explain-connected' role='alert'>
                          <div style={{ color: 'red' }} className='ant-form-item-explain-error'>Please enter label</div>
                      </div>
                  </div>
          }
              </div>
              <div className='controllerItem'>
                  <label>Tag Category</label>
                  <Select
                      placeholder='Assign a tag category'
                      value={tagSelected?.categoryId}
                      className={submited === true && !tagSelected.categoryId ? 'ant-select-outlined ant-select-status-error' : ''}
                      onChange={(e) => {

                        setTagSelected({
                          ...tagSelected,
                          categoryId: e
                        });

                      }}

                      style={{
                        width: '100%'
                      }}
                      options={tagCategories.map((x) => ({
                        value: x._id,
                        label: <div> <span className='categoryColor' style={{ backgroundColor: x.color }}></span> {x.label}</div>
                      }))}
          />
                  {submited === true && !tagSelected.categoryId &&
                  <div>
                      <div id='username_help' className=' ant-form-item-explain-connected' role='alert'>
                          <div style={{ color: 'red' }} className='ant-form-item-explain-error'>Please select category</div>
                      </div>
                  </div>
          }
              </div>
              <div className='controllerItem'>
                  <label>Description</label>
                  <Input.TextArea
                      value={tagSelected.description || ''}
                      onChange={(e) => {

                        setTagSelected({
                          ...tagSelected,
                          description: e.target.value
                        });

                      }}
                      rows={4}
                      placeholder='Describe your tag here'
          />
              </div>
          </Modal>
      </div>
  );

}

TagComponent.propTypes = {
  openControllModal: PropTypes.bool,
  setOpenControllModal: PropTypes.any
};

import { React, useEffect, useMemo, useState } from 'react';
import { Button, DatePicker, Dropdown, Select, Space, Tag, Tooltip } from 'antd';
import dayjs from 'dayjs';

import { SwapOutlined, UnlockOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';

import { useAccountStore, useManagementStore, useSettingStore, useUserState } from '../../../../store';
import axios from '../../../../utilities/axios';
import { TRADES } from '../../../../utilities/functions';

import './styles.scss';

const { RangePicker } = DatePicker;

// const tagRender = (props) => {

//   console.log(props);
//   const { label } = props;
//   return (
//       <span>{label}, </span>
//   );

// };

const assetOptions = [
  {
    label: 'Stocks',
    value: 'Stock'
  },
  {
    label: 'Options',
    value: 'Option'
  },
  {
    label: 'Future',
    value: 'Future'
  },
  {
    label: 'Cash',
    value: 'Cash'
  }
];

const basicMeasureOptions = [
  {
    key: 'Dollar',
    label: 'Dollar',
    value: 'Dollar',
    icon: <span style={{ fontSize: 16 }}>$</span>
  },
  {
    key: 'R-multiple',
    label: 'R-multiple',
    value: 'R-multiple',
    icon: <span style={{ fontSize: 16 }}>R</span>
  }
];

const rangePresets = [
  {
    label: 'Last 7 Days',
    value: [dayjs().add(-7, 'd'), dayjs()]
  },
  {
    label: 'Last 30 Days',
    value: [dayjs().add(-30, 'd'), dayjs()]
  },
  {
    label: 'Last 3 Months',
    value: [dayjs().add(-3, 'M'), dayjs()]
  },
  {
    label: 'Last 6 Months',
    value: [dayjs().add(-6, 'M'), dayjs()]
  },
  {
    label: 'Last 12 Months',
    value: [dayjs().add(-12, 'M'), dayjs()]
  },
  {
    label: 'Last 5 Years',
    value: [dayjs().add(-5, 'y'), dayjs()]
  }
];

const fetchTags = (user, viewAs) => {

  const isViewAsActive = JSON.stringify(viewAs?.newUser?.token || null) !== JSON.stringify(viewAs?.mainUser?.token || null);

  const results = axios
    .get('crud/tag', { headers: { token: isViewAsActive ? viewAs.newUser.token : user?.token } })
    .then(({ data }) => data)
    .catch((error) => {

      if (error.response.status === 404) {

        return [];

      }
      return error;

    });

  return results;

};

export default function SubHeader() {

  const { allAccounts, changeAccount } = useAccountStore((state) => state);
  const { getUser } = useUserState();
  const { viewAs } = useManagementStore((state) => state);
  const { filter, changeFilter } = useSettingStore((state) => state);
  const onChangeAssetType = (checkedValues) => changeFilter('assetType', checkedValues);
  const onChangeAccounts = (checkedValues) => changeFilter('accounts', checkedValues);
  const onChangeTags = (checkedValues) => changeFilter('tags', checkedValues);
  const onChangeSymbols = (checkedValues) => changeFilter('symbols', checkedValues);
  const onChangeStatus = (checkedValues) => changeFilter('status', checkedValues);
  const onChangeSide = (checkedValues) => changeFilter('side', checkedValues);
  const onChangeRange = (rangeArray) => changeFilter('dateRange', rangeArray);
  const onChangeBasicMeasure = (checkedValues) => changeFilter('basic_measure', checkedValues);
  const isViewAsActive = JSON.stringify(viewAs?.newUser?.token || null) !== JSON.stringify(viewAs?.mainUser?.token || null);
  const [lastUploadDate, setLastUploadDate] = useState();
  const [userAccounts, setUserAccounts] = useState([]);
  const user = getUser();

  const sides = ['Long', 'Short'];
  const statuses = ['Open', 'Closed'];

  const { data: tags = [] } = useQuery(['tags', viewAs], () => fetchTags(user, viewAs));
  const { data: trades = [] } = useQuery(
    ['trades', { accounts: filter.accounts }],
    () => TRADES.fetchTrades({
      accounts: filter.accounts,
      assetType: [
        'Stock',
        'Option',
        'Future',
        'Cash'
      ]
    }, userAccounts)
  );

  // Checking all accounts in case it's first access and store doesn't contain any filter changes
  useEffect(() => {

    if (allAccounts.length === 0) {

      onChangeAccounts([]); // Make sure filter is empty if no accounts

    } else if (Object.keys(filter.accounts).length === 0 && allAccounts.length > 0) {

      const liveTradeAccount = allAccounts?.filter((account) => account.label === 'Live Trade');
      if (isViewAsActive === false) {

        onChangeAccounts([allAccounts[0].key]);

      } else if (liveTradeAccount.length > 0) {

        onChangeAccounts([liveTradeAccount[0].key]);

      }

      onChangeAccounts([]);

    }

    if (allAccounts.length > 0) {

      const accounts = allAccounts?.filter((account) => {

        if (isViewAsActive === false || (isViewAsActive === true && account.label === 'Live Trade')) {

          return account;

        }

        return null;

      });

      const uploadDates = accounts.map((account) => new Date(account.executionsLogs[account.executionsLogs.length - 1]?.uploadTime));

      if (uploadDates.length > 0) {

        const lastUpload = dayjs(Math.max(...uploadDates)).format('MMM DD, YYYY HH:mm A');
        setLastUploadDate(lastUpload);

      }

      changeFilter('accounts', accounts.map((a) => a.key));
      changeAccount(accounts[0]);
      setUserAccounts(accounts);

    }

    // eslint-disable-next-line padded-blocks
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allAccounts]);

  const accountsOptions = userAccounts.map((i) => ({
    label: i.label,
    value: i.key
  })) || [];

  const uniqueSymbols = useMemo(() => [...new Set(trades?.map((trade) => trade.symbol))], [trades]);

  const handleTagClose = (e, tag) => {

    e.preventDefault();
    onChangeTags(filter.tags.filter((t) => t !== tag._id));

  };

  useEffect(() => {

    if (uniqueSymbols.length > 0) {

      onChangeSymbols([...uniqueSymbols]);

    }

  }, [uniqueSymbols]);

  return (
      <>
          <div className='subheader-container'>
              <Space direction='horizontal' className='subheader-items' align='center' wrap>
                  {userAccounts.length > 0 ? (
                      <>
                          <Space className='first-filter-row' wrap={true}>
                              <Tooltip title='Change Measurement' key='measure'>
                                  <Dropdown
                                      menu={{
                                        items: basicMeasureOptions,
                                        selectable: true,
                                        defaultSelectedKeys: [filter.basic_measure || 'Dollar'],
                                        onClick: ({ key }) => onChangeBasicMeasure(key)
                                      }}
                  >
                                      <Button icon={basicMeasureOptions.filter((option) => option.key === filter.basic_measure)[0].icon} />
                                  </Dropdown>
                              </Tooltip>
                              <span className='prefix-icon tt-calendar'></span>
                              {filter.dateRange ?
                                  <RangePicker
                                      className='custom-range-picker'
                                      onChange={onChangeRange}
                                      value={[dayjs(filter.dateRange[0]), dayjs(filter.dateRange[1])]}
                                      presets={rangePresets}
                                      suffixIcon={false} />
                                :
                                  <RangePicker
                                      className='custom-range-picker'
                                      onChange={onChangeRange}
                                      value={[null, null]}
                                      presets={rangePresets}
                                      suffixIcon={false} />
                }
                              <span className='prefix-icon tt-account_balance'></span>
                              <Select
                                  mode='multiple'
                                  style={{ width: 280 }}
                                  onChange={onChangeAccounts}
                                  options={accountsOptions}
                                  value={filter.accounts}
                                  maxTagCount={1}
                                  showSearch={false}
                                  tagRender={(tag) => (
                                      <span className={tag.value && filter.accounts.indexOf(tag.value) < 1 ? 'custom-select-input' : ''}>
                                          {tag.label}
                                          {tag.value && filter.accounts.indexOf(tag.value) < filter.accounts.length - 1 ? ',\u00A0' : ''}
                                      </span>
                                  )}
                />
                              <span className='prefix-icon tt-account_balance_wallet'></span>
                              <Select
                                  mode='multiple'
                                  defaultValue={filter.assetType}
                                  style={{ width: 280 }}
                                  onChange={onChangeAssetType}
                                  options={assetOptions}
                                  value={filter.assetType}
                                  maxTagCount={2}
                                  showSearch={false}
                                  tagRender={(tag) => (
                                      <span className={tag.value && filter.assetType.indexOf(tag.value) < 1 ? 'custom-select-input' : ''}>
                                          {tag.label}
                                          {tag.value && filter.assetType.indexOf(tag.value) < filter.assetType.length - 1 ? ',\u00A0' : ''}
                                      </span>
                                  )}
                />
                              <span className='prefix-icon'>#</span>
                              <Select
                                  mode='multiple'
                                  style={{ width: 280 }}
                                  onChange={onChangeTags}
                                  placeholder='Tags'
                                  options={tags.map((item) => ({ label: item.label, value: item._id }))}
                                  value={filter.tags}
                                  maxTagCount={1}
                                  showSearch={false}
                                  tagRender={(tag) => (
                                      <span className={tag.value && filter.tags.indexOf(tag.value) < 1 ? 'custom-select-input' : ''}>
                                          {tag.label}
                                          {tag.value && filter.tags.indexOf(tag.value) < filter.tags.length - 1 ? ',\u00A0' : ''}
                                      </span>
                                  )}
                />
                              {lastUploadDate &&
                              <Space>
                                  <span className='tt-autorenew'></span>
                                  <span>Last upload on {lastUploadDate}</span>
                              </Space>}
                          </Space>
                          <Space wrap={true}>
                              <span className='prefix-icon tt-Building' />
                              <Select
                                  mode='multiple'
                                  style={{ width: 280 }}
                                  onChange={onChangeSymbols}
                                  placeholder='Symbol'
                                  options={uniqueSymbols.map((item) => ({ label: item, value: item, key: item }))}
                                  value={filter.symbols.filter((s) => uniqueSymbols.includes(s))}
                                  maxTagCount={1}
                                  showSearch={false}
                                  tagRender={(tag) => (
                                      <span className={filter.tags.indexOf(tag.value) < 1 ? 'custom-select-input' : ''}>
                                          {tag.label.length > 11 ? `${tag.label.slice(0, 11)}...` : tag.label}
                                          {filter.tags.indexOf(tag.value) < filter.tags.length - 1 ? ',\u00A0' : ''}
                                      </span>
                                  )}
                />
                              <span className='prefix-icon'><SwapOutlined /></span>
                              <Select
                                  mode='multiple'
                                  style={{ width: 280 }}
                                  onChange={onChangeSide}
                                  placeholder='Side'
                                  options={sides.map((item) => ({ label: item, value: item, key: item }))}
                                  value={filter.side}
                                  maxTagCount={1}
                                  showSearch={false}
                                  tagRender={(tag) => (
                                      <span className={filter.tags.indexOf(tag.value) < 1 ? 'custom-select-input' : ''}>
                                          {tag.label}
                                          {filter.tags.indexOf(tag.value) < filter.tags.length - 1 ? ',\u00A0' : ''}
                                      </span>
                                  )}
                />
                              <span className='prefix-icon'><UnlockOutlined /></span>
                              <Select
                                  mode='multiple'
                                  style={{ width: 280 }}
                                  onChange={onChangeStatus}
                                  placeholder='Status'
                                  options={statuses.map((item) => ({ label: item, value: item.toLowerCase(), key: item }))}
                                  value={filter.status}
                                  maxTagCount={1}
                                  showSearch={false}
                                  tagRender={(tag) => (
                                      <span className={filter.tags.indexOf(tag.value) < 1 ? 'custom-select-input' : ''}>
                                          {tag.label}
                                          {filter.tags.indexOf(tag.value) < filter.tags.length - 1 ? ',\u00A0' : ''}
                                      </span>
                                  )}
                />
                          </Space>
                      </>
                  ) : (
                      <></>
                  )}
              </Space>
              {userAccounts.length > 0 ?
                  <Space className='subheader-tags'>
                      {tags.map((tag) => (
                        filter.tags.includes(tag._id) &&
                        <Tag
                            className='tag-item'
                            closable
                            onClose={(e) => handleTagClose(e, tag)}
                            key={tag._id}>
                            {tag.label}
                        </Tag>
                      ))}
                  </Space> : <></>}
          </div>
      </>
  );

}

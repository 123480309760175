const brokers = [
  // {
  //   label: 'Thinkorswim',
  //   value: 'think_or_swim'
  // },
  // {
  //   label: 'Interactive Brokers',
  //   value: 'interactive_brokers'
  // },
  // {
  //   label: 'E-Trade (Power E-Trade)',
  //   value: 'power_etrade'
  // },
  // {
  //   label: 'Generic',
  //   value: 'generic'
  // },
  // {
  //   label: 'Silexx',
  //   value: 'silexx'
  // },
  // {
  //   label: 'Trade Station',
  //   value: 'trade_station'
  // },
  // {
  //   label: 'Webull',
  //   value: 'webull'
  // },
  // {
  //   label: 'Questrade',
  //   value: 'questrade'
  // },
  // {
  //   label: 'Lightspeed',
  //   value: 'lightspeed'
  // },
  // {
  //   label: 'Robinhood',
  //   value: 'robinhood'
  // },
  // {
  //   label: 'Tastyworks',
  //   value: 'tastyworks'
  // },
  // {
  //   label: 'NinjaTrader',
  //   value: 'ninja'
  // },
  // {
  //   label: 'Tradovate',
  //   value: 'tradovate'
  // },
  // {
  //   label: 'TradeZero',
  //   value: 'tradezero'
  // },
  // {
  //   label: 'MetaTrader 4',
  //   value: 'mt4'
  // },
  {
    label: 'DasTrader',
    value: 'das_trader'
  }
  // {
  //   label: 'Sterling Trader Pro',
  //   value: 'sterling'
  // },
  // {
  //   label: 'Sierra Chart',
  //   value: 'sierra_chart'
  // },
  // {
  //   label: 'TD Direct Investments',
  //   value: 'td_direct_investments'
  // },
  // {
  //   label: 'Refinitiv Redi',
  //   value: 'redi'
  // },
  // {
  //   label: 'MetaTrader 5',
  //   value: 'mt5'
  // },
  // {
  //   label: 'Rithmic R Trader',
  //   value: 'rithmic'
  // },
  // {
  //   label: 'TradingView',
  //   value: 'trading_view'
  // },
  // {
  //   label: 'TC2000',
  //   value: 'tc2000'
  // },
  // {
  //   label: 'Zerodha',
  //   value: 'zerodha'
  // },
  // {
  //   label: 'Coinbase',
  //   value: 'coinbase'
  // },
  // {
  //   label: 'Coinbase Pro',
  //   value: 'coinbase_pro'
  // },
  // {
  //   label: 'Quantower',
  //   value: 'quantower'
  // },
  // {
  //   label: 'TEFS Evolution',
  //   value: 'tefs_evolution'
  // }
];

const profitCalculationMethods = [
  {
    label: 'Weighted Average',
    value: 'average'
  },
  {
    label: 'FIFO',
    value: 'fifo'
  },
  {
    label: 'LIFO',
    value: 'lifo'
  }
];

const advancedSettingsInputs = {
  options: [{
    label: 'Close all expired options',
    value: 'closeExpiredOptions'
  }],
  target_and_stop_loss: [{
    label: 'Auto Target Profit & Stop Loss',
    value: 'applyTargetProfitAndStopLoss'
  }]
};

const dasCommissionOptions = [
  {
    label: 'Apply no commission',
    value: 'noCommission'
  },
  {
    label: 'Apply predefined profiles',
    value: 'forceOverwriteCommission'
  }
];

const iBCommissionOptions = [
  {
    label: 'Apply no commission',
    value: 'noCommission'
  },
  {
    label: 'Apply file commissions',
    value: 'applyFileCommissions'
  },
  {
    label: 'Apply predefined profiles',
    value: 'forceOverwriteCommission'
  }
];

module.exports = {
  brokers,
  profitCalculationMethods,
  advancedSettingsInputs,
  dasCommissionOptions,
  iBCommissionOptions
};

import React from 'react';
import { Card, Col, Image, Row, Space, Tag } from 'antd';
import Link from 'antd/es/typography/Link';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';

import UploadBox from '../../components/upload-box';
// eslint-disable-next-line camelcase
import DAS_Logo from '../../resources/images/DAS_Logo.png';
import { useAccountStore, useManagementStore, useUserState } from '../../store';

import './styles.scss';

const DasInstruction =
    <div>
        <Image
        // eslint-disable-next-line camelcase
            src={DAS_Logo}
            preview={false}
            height={30}
        />
        <Title level={4}>Supported Asset Types:</Title>
        <Tag color='processing'>Stocks</Tag>
        <Tag color='processing'>Options</Tag>
        <Title level={4}>How to Import Trades from DAS Trader:</Title>
        <Paragraph>
            To import trades from DAS Trader Pro, follow these steps:
            <ol>
                <li>
                    Login to your DAS Trader Pro desktop platform.
                </li>
                <li>
                    Click on the Trades Window and select the following columns for your export: Time, Symb, Side, Price, Qty, Route, Broker, Account, Type, Cloid.
                </li>
                <li>
                    If trading options: Right-click on the option chain window, then select  &quot;Config&quot; {'>'} and check  &quot;Use Long Symbol&quot;.
                </li>
                <li>
                    Right-click and export your trades into a CSV file from the Trades Window.
                </li>
                <li>
                    On the import window, select the date of the trades you are
                    importing. You must select a date because the data exported from DAS Trader Pro does not include trade dates.
                </li>
                <li>
                    Click  &quot;Upload file &quot; to import your trades.
                </li>
            </ol>
        </Paragraph>
        <Title level={4}>Notes:</Title>
        <Paragraph>
            <ul>
                <li>
                    DAS Trader only lets you export trades taken on the same day.
                </li>
                <li>
                    We recommend exporting your trades as soon as you are done trading for the day.
                </li>
            </ul>
        </Paragraph>
        <Title level={4}>Tutorial:</Title>
        <Link href='https://player.vimeo.com/video/1002080275?title=0&byline=0&portrait=0&badge=0&autopause=0&app_id=58479' target='_blank'>Watch Video</Link>
    </div>;

export default function AddTradePage() {

  const { allAccounts } = useAccountStore((state) => state);

  const { getUser } = useUserState();
  const user = getUser();
  const { viewAs, setViewAs } = useManagementStore((state) => state);

  const isViewAsActive = JSON.stringify(viewAs?.newUser?.token || null) !== JSON.stringify(viewAs?.mainUser?.token || null);

  if (isViewAsActive) setViewAs({ token: user?.token, id: user?.id }, { token: user?.token, id: user?.id });

  return (
      <Space className='add-trade'>
          <Row gutter={[10, 10]} style={{ width: '100%' }}>
              <Col span={9}>
                  <Card>
                      {allAccounts?.length && <UploadBox/>}
                  </Card>
              </Col>
              <Col span={15}>
                  <Card>
                      {DasInstruction}
                  </Card>
              </Col>
          </Row>
      </Space>
  );

}

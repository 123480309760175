import React from 'react';
import { Image, Space } from 'antd';

import LogoImg from '../../../../resources/images/Logo.svg';

const Logo = () => (
    <Space direction='horizontal'>
        <Image
            width={'100%'}
            src={LogoImg}
            preview={false}
            style={{ paddingLeft: '0.75rem' }}
        />
        <h3>Trading Terminal</h3>
    </Space>
);
export default Logo;

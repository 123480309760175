import { React, useState } from 'react';
import { Button, Card, Col, Input, Row, Select, Space, Table } from 'antd';
import qs from 'qs';

import { useQuery } from '@tanstack/react-query';

import { useUserState } from '../../store';
import axios from '../../utilities/axios';
import { FORMAT } from '../../utilities/functions';

import './styles.scss';

const fetchTags = (user) => {

  const results = axios
    .get('crud/tag', { headers: { token: user?.token } })
    .then(({ data }) => data)
    .catch((error) => {

      if (error.response.status === 404) {

        return [];

      }
      return error;

    });

  return results;

};

const columns = [
  {
    title: '',
    dataIndex: 'key',
    rowScope: 'row',
    render: (item) => (
        <p style={{ fontWeight: 'bold', fontSize: '1rem' }}>{item}</p>
    )
  },
  {
    title: 'Group A',
    dataIndex: 'groupA',
    key: 'groupA',
    render: (item, r) => (
        <p
            title='Winner'
            style={r.winner.includes('groupA') && r.winner.length < 3 ? { fontWeight: 'bold', fontSize: '1rem' } : { fontSize: '1rem' }}>
            {item} {r.winner.includes('groupA') && r.winner.length < 3 ? '✅' : ''}
        </p>
    )
  },
  {
    title: 'Group B',
    dataIndex: 'groupB',
    key: 'groupB',
    render: (item, r) => (
        <p
            title='Winner'
            style={r.winner.includes('groupB') && r.winner.length < 3 ? { fontWeight: 'bold', fontSize: '1rem' } : { fontSize: '1rem' }}>
            {item} {r.winner.includes('groupB') && r.winner.length < 3 ? '✅' : ''}
        </p>
    )
  },
  {
    title: 'Group C',
    dataIndex: 'groupC',
    key: 'groupC',
    render: (item, r) => (
        <p
            title='Winner'
            style={r.winner.includes('groupC') && r.winner.length < 3 ? { fontWeight: 'bold', fontSize: '1rem' } : { fontSize: '1rem' }}>
            {item} {r.winner.includes('groupC') && r.winner.length < 3 ? '✅' : ''}
        </p>
    )
  }
];

export default function CompareCard() {

  const [selectedTagsA, setSelectedTagsA] = useState([]);
  const [selectedTagsB, setSelectedTagsB] = useState([]);
  const [selectedTagsC, setSelectedTagsC] = useState([]);
  const [selectedStockA, setSelectedStockA] = useState([]);
  const [selectedStockB, setSelectedStockB] = useState([]);
  const [selectedStockC, setSelectedStockC] = useState([]);
  const [compareResult, setCompareResult] = useState();

  const { getUser } = useUserState();
  const user = getUser();

  const { data: tags = [] } = useQuery(['tags'], () => fetchTags(user));

  const onChangeTagsA = (value) => setSelectedTagsA(value);
  const onChangeTagsB = (value) => setSelectedTagsB(value);
  const onChangeTagsC = (value) => setSelectedTagsC(value);
  const onChangeStockA = (value) => setSelectedStockA(value);
  const onChangeStockB = (value) => setSelectedStockB(value);
  const onChangeStockC = (value) => setSelectedStockC(value);

  const fetchCompare = (tagsA, tagsB, tagsC, stockA, stockB, stockC) => axios.get('stats/compare', {
    params: { tagsA, tagsB, tagsC, stockA, stockB, stockC },
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' })
  }).then((response) => {

    let responseData = null;

    if (response.status === 200) {

      responseData = FORMAT.COMPARE_TABLE_BEAUTIFY(response.data);

    }

    return responseData;

  }).catch((error) => {

    console.error('Error:', error);

  });

  const handleCompare = async () => {

    const compareData = await fetchCompare(selectedTagsA, selectedTagsB, selectedTagsC, selectedStockA, selectedStockB, selectedStockC);
    setCompareResult(compareData);

  };

  return (
      <Space direction='vertical' style={{ width: '100%', paddingTop: '3.375rem' }} wrap>
          <Row gutter={[20, 20]} style={{ width: '100%', paddingBottom: '1.375rem' }}>
              <Col span={8}>
                  <Card title='Group A' className='grey-card'>
                      <Row gutter={[10, 10]} style={{ width: '100%' }}>
                          <Col span={8}>
                              <span>Symbol: </span>
                              <Input placeholder='Symbol' onChange={(e) => onChangeStockA(e.currentTarget.value)} />
                          </Col>
                          <Col span={16}>
                              <span>Tags: </span>
                              <Select
                                  mode='multiple'
                                  style={{ width: '100%' }}
                                  onChange={onChangeTagsA}
                                  placeholder='Select tags'
                                  options={tags.map((item) => ({ label: item.label, value: item._id }))}
                                  value={selectedTagsA}
                                  maxTagCount={1}
                                  showSearch={false}
                                />
                          </Col>
                      </Row>
                  </Card>
              </Col>
              <Col span={8}>
                  <Card title='Group B' className='grey-card'>
                      <Row gutter={[10, 10]} style={{ width: '100%' }}>
                          <Col span={8}>
                              <span>Symbol: </span>
                              <Input placeholder='Symbol' onChange={(e) => onChangeStockB(e.currentTarget.value)} />
                          </Col>
                          <Col span={16}>
                              <span>Tags: </span>
                              <Select
                                  mode='multiple'
                                  style={{ width: '100%' }}
                                  onChange={onChangeTagsB}
                                  placeholder='Select tags'
                                  options={tags.map((item) => ({ label: item.label, value: item._id }))}
                                  value={selectedTagsB}
                                  maxTagCount={1}
                                  showSearch={false}
                                />
                          </Col>
                      </Row>
                  </Card>
              </Col>
              <Col span={8}>
                  <Card title='Group C' className='grey-card'>
                      <Row gutter={[10, 10]} style={{ width: '100%' }}>
                          <Col span={8}>
                              <span>Symbol: </span>
                              <Input placeholder='Symbol' onChange={(e) => onChangeStockC(e.currentTarget.value)} />
                          </Col>
                          <Col span={16}>
                              <span>Tags: </span>
                              <Select
                                  mode='multiple'
                                  style={{ width: '100%' }}
                                  onChange={onChangeTagsC}
                                  placeholder='Select tags'
                                  options={tags.map((item) => ({ label: item.label, value: item._id }))}
                                  value={selectedTagsC}
                                  maxTagCount={1}
                                  showSearch={false}
                                />
                          </Col>
                      </Row>
                  </Card>
              </Col>
          </Row>
          <Button size='large' onClick={handleCompare} type='primary' >Compare</Button>
          <Row gutter={[0, 0]} style={{ width: '100%', paddingTop: '1.375rem' }}>
              <Col span={24}>
                  {compareResult && <Table
                    //   rowKey={(r) => r.date + r.remainingShares} // trying unique key
                      columns={columns}
                      dataSource={compareResult}
                      pagination={false}
                    /> }
              </Col>
          </Row>
      </Space>
  );

}

/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Card, Col, Descriptions, Divider, InputNumber, Row, Space, Statistic, Table, Tag } from 'antd';
import dayjs from 'dayjs';
import { useParams } from 'react-router';

import { useQuery } from '@tanstack/react-query';

import TVChartContainer from '../../components/tradingview/full-chart';
import { useAccountStore, useManagementStore, useSettingStore, useUserState } from '../../store';
import axios from '../../utilities/axios';
import { FORMAT, LOGS } from '../../utilities/functions';

import TradeNotes from './trade-notes';
import TradeTags from './trade-tags';

import './styles.scss';

const columns = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date'
  },
  {
    title: 'Symbol',
    dataIndex: 'symbol',
    key: 'symbol'
  },
  {
    title: 'Side',
    dataIndex: 'side',
    key: 'side'
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price'
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity'
  },
  {
    title: 'Realized P&L',
    dataIndex: 'realizedGain',
    key: 'realizedGain',
    render: (netPnL) => {

      let color;
      const value = FORMAT.CURRENCY_TO_NUMBER(netPnL);
      if (value > 0) {

        color = '#29a373';

      } else if (value < 0) {

        color = '#E57373';

      } else {

        color = 'grey';

      }
      return (
          <Tag color={color} key={netPnL}>
              {netPnL}
          </Tag>
      );

    }
  }
];

export default function TradeIndividualPage() {

  const [profitTargetValue, setProfitTarget] = useState('');
  const [initialTarget, setInitialTarget] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [trade, setTrade] = useState({});
  const [tradeRisk, setTradeRisk] = useState('');
  const [plannedR, setPlannedR] = useState('');
  const [timezone, setTimeZone] = useState();
  const [realizedR, setRealizedR] = useState('');
  const [stopLossValue, setStopLoss] = useState('');
  const [tradeNotes, setTradeNotes] = useState(null);
  const { setMarks } = useSettingStore((state) => state);
  const { id } = useParams();
  const { getUser } = useUserState();
  const { viewAs } = useManagementStore((state) => state);
  const user = getUser();
  const { setAllAccounts, allAccounts } = useAccountStore((state) => state);

  const [isViewAsActive, setViewAsActive] = useState(JSON.stringify(viewAs?.newUser?.token || null) !== JSON.stringify(viewAs?.mainUser?.token || null));

  const fetchAccounts = () => axios.get('crud/account', {
    headers: {
      token: isViewAsActive ? viewAs.newUser.token : user?.token
    }
  }).then(({ data }) => data.map((a) => FORMAT.ACCOUNTS_BEAUTIFY(a)));

  const { isLoading: isAccountLoading } = useQuery(['accounts', viewAs], () => fetchAccounts().then(async (accounts) => {

    const results = await LOGS.processLogs(accounts, setAllAccounts);

    return results;

  }), { enabled: user.token !== undefined });

  const addProfitTargetToTrade = (profitTarget) => axios.put(`crud/trades/profittarget/${id}`, { profitTarget }).then(({ data }) => {

    const beautifulData = FORMAT.TRADE_BEAUTIFY(data, timezone);
    setProfitTarget(profitTarget);
    setPlannedR(beautifulData.plannedRMultiple);
    setRealizedR(beautifulData.realizedRMultiple);
    setInitialTarget(beautifulData.initialTarget);

  });

  const addStopLossToTrade = (stopLoss) => axios.put(`crud/trades/stoploss/${id}`, { stopLoss }).then(({ data }) => {

    const beautifulData = FORMAT.TRADE_BEAUTIFY(data, timezone);
    setStopLoss(stopLoss);
    setPlannedR(beautifulData.plannedRMultiple);
    setRealizedR(beautifulData.realizedRMultiple);
    setTradeRisk(beautifulData.tradeRisk);

  });

  const formattedSymbol = trade.assetType === 'Option' ? trade.optionContract.underlying : trade.symbol;

  useEffect(() => {

    setIsLoading(true);

    axios.get(`crud/trades/${id}`)
      .then(({ data }) => {

        const { brokerTimezone } = allAccounts.find((a) => a.key === data.accountRef._id);

        if (user.role === 'admin' || viewAs?.newUser?.id === data.accountRef._id || viewAs?.mainUser?.id === data.accountRef._id) {

          setViewAsActive(false);

        }

        setTimeZone(() => brokerTimezone);

        setTrade(FORMAT.TRADE_BEAUTIFY(data, brokerTimezone));

      }).finally(() => {

        setIsLoading(false);

      });

  }, [id]);

  useEffect(() => {

    // Preparing TradingView Marks. This works with either createShape method and createExecutionShape
    if (Object.keys(trade).length > 0) {

      // We don't want to show trades for Option trades
      if (trade.executions && trade.executions.length > 0 && trade.assetType !== 'Option') {

        const marks = trade.executions.map((e) => e.mark);
        setMarks(marks);

      }

    }

    if (trade.profit_target) {

      setProfitTarget(trade.profit_target);

    }

    if (trade.stop_loss) {

      setStopLoss(trade.stop_loss);

    }

    if (trade.initialTarget) {

      setInitialTarget(trade.initialTarget);

    }

    if (trade.tradeRisk) {

      setTradeRisk(trade.tradeRisk);

    }

    if (trade.plannedRMultiple) {

      setPlannedR(trade.plannedRMultiple);

    }

    if (trade.realizedRMultiple) {

      setRealizedR(trade.realizedRMultiple);

    }

    if (trade.notes) {

      setTradeNotes(trade.notes);

    }

  }, [setMarks, trade]);

  if (isLoading || isAccountLoading) return <></>;

  return (
      <>
          <Row gutter={[24, 24]} style={{ width: '100%', padding: '40px 0' }}>
              <Col span={8}>
                  <Card bordered={true}>
                      <Statistic
                          title='Profit & Loss'
                          value={trade.netPnL}
                          precision={2}
                          valueStyle={
                FORMAT.CURRENCY_TO_NUMBER(trade.netPnL) > 0 ? {
                  color: '#29a373'
                } : {
                  color: '#E57373'
                }}
            />
                      {trade.status !== 'open' && <Divider style={{ margin: '12px 0' }} />}

                      <Space direction='vertical'>
                          {/* <TagInput initialTags={trade.tags} tradeID={id} accountID={account.key} tradeStatus={trade.status} /> */}

                          <InputNumber
                              addonBefore='Profit Target'
                              value={profitTargetValue}
                              formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                              onChange={addProfitTargetToTrade}
                              disabled={isViewAsActive}
              />
                          <InputNumber
                              addonBefore='Stop Loss'
                              value={stopLossValue}
                              formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                              onChange={addStopLossToTrade}
                              disabled={isViewAsActive}
              />
                      </Space>
                      <Divider style={{ margin: '12px 0' }} />
                      <Descriptions
                          title='Summary'
                          bordered
                          column={1}
                          size='small'
            >
                          <Descriptions.Item label='Symbol'>{trade.symbol}</Descriptions.Item>
                          <Descriptions.Item label='Side'>{trade.side}</Descriptions.Item>
                          <Descriptions.Item label='Open Time'>{trade.openTime}</Descriptions.Item>
                          <Descriptions.Item label='Close Time'>{trade.closeTime}</Descriptions.Item>
                          <Descriptions.Item label='Open Price'>{trade.openPrice}</Descriptions.Item>
                          <Descriptions.Item label='Close Price'>{trade.closePrice}</Descriptions.Item>
                          <Descriptions.Item label='Total Volume'>{trade.totalVolume}</Descriptions.Item>
                          <Descriptions.Item label='Number of Executions'>{trade.executionsNumber}</Descriptions.Item>
                          <Descriptions.Item label='Initial Target'>{initialTarget}</Descriptions.Item>
                          <Descriptions.Item label='Trade Risk'>{tradeRisk}</Descriptions.Item>
                          <Descriptions.Item label='Planned R-Multiple'>{plannedR}</Descriptions.Item>
                          <Descriptions.Item label='Realized R-Multiple'>{realizedR}</Descriptions.Item>
                      </Descriptions>
                  </Card>
              </Col>
              <Col span={16}>
                  {trade.executions && <Card title='Chart' bordered={true}>
                      <TVChartContainer
                          symbol={formattedSymbol}
                          startDate={dayjs(trade.executions[0].date).valueOf() / 1000}
                          endDate={dayjs(trade.executions[trade.executions.length - 1].date).valueOf() / 1000}
                          timezone={timezone}
            />
                  </Card>}
              </Col>
          </Row>
          <Row gutter={[24, 24]} style={{ width: '100%', padding: '40px 0' }}>
              <Col span={8}>
                  <Card bordered={true}>
                      <TradeTags
                          tradeId={trade._id}
                          initialTags={trade.tags}
                          token={isViewAsActive ? viewAs.newUser.token : user?.token}
                          tradeStatus={trade.status} />
                  </Card>
              </Col>
              <Col span={16}>
                  <Card title='Executions' bordered={true}>
                      <Table
                          rowKey={(record) => record.date + record.quantity}
                          columns={columns}
                          dataSource={trade.executions}
                          pagination={false}
            />
                  </Card>
              </Col>
          </Row>
          <Row gutter={[24, 24]} style={{ width: '100%', padding: '40px 0' }}>
              {tradeNotes !== null && <TradeNotes tradeId={trade._id} setTradeNotes={setTradeNotes} isViewAsActive={isViewAsActive} tradeNotes={tradeNotes} />}
          </Row>
      </>
  );

}

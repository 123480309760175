// auth.js

import { useEffect, useState } from 'react';
import axios from 'axios';

import { useUserState } from '../../store';
import config from '../config';

const useAuthentication = () => {

  const { getUser, setUser, refreshUser } = useUserState();
  const { pathname, search } = window.location;
  const isJournalingSSO = pathname.startsWith('/');
  const [isUserSet, setIsUserSet] = useState(false); // Track whether the user is set

  useEffect(() => {

    const authenticateUser = async () => {

      const params = new URLSearchParams(search);

      if (isJournalingSSO && params.get('token')) {

        try {

          const response = await axios.get(`${config.get('PHANTOM_BASE_URL')}/user/sso?token=${params.get('token')}`);
          const { user, token } = response.data;
          if (user) {

            await setUser({
              name: user.first_name, email: user.email, lastName: user.last_name, avatar: user.image, token, darkMode: user.darkMode || false, role: user.role, id: user._id
            });
            setIsUserSet(true); // Set userSet to true after user is set

          }

        } catch (error) {

          console.error(error);

        }

      } else {

        const isAuthenticated = Boolean(getUser().token);

        if (!isAuthenticated) {

          window.location.replace(`${config.get('TRADING_TERMINAL_BASE_URL')}/journal`);

        } else {

          refreshUser(); // Refresh user with latest DB info
          setIsUserSet(true); // Set userSet to true if user is already authenticated

        }

      }

    };

    authenticateUser();

  }, [isJournalingSSO, search]);

  return isUserSet; // Return userSet state to indicate whether the user is set

};

export default useAuthentication;

import React, { useEffect, useState } from 'react';
import { Col, Row, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import qs from 'qs';

import { useQuery } from '@tanstack/react-query';

import DashboardSummaryTable from '../../components/summary-table';
import { useManagementStore, useSettingStore } from '../../store';
import axios from '../../utilities/axios';
import { dashboard } from '../../utilities/descriptions';
import { FORMAT } from '../../utilities/functions';

import StatsCards from './components/stats-cards';

const { Title, Text } = Typography;

export default function Dashboard() {

  const { filter, changeFilter } = useSettingStore((state) => state);
  const { viewAs } = useManagementStore((state) => state);
  const [currentUser, setCurrentUser] = useState(viewAs);

  const fetchAccountSummary = () => axios.get('stats/summary', {
    params: {
      accounts: filter.accounts,
      assetType: filter.assetType,
      tags: filter.tags,
      symbols: filter.symbols,
      status: filter.status,
      side: filter.side,
      startDate: filter.dateRange ? filter.dateRange[0]?.toString() : dayjs().add(-5, 'y').toDate(),
      endDate: filter.dateRange ? filter.dateRange[1]?.toString() : dayjs().toDate()
    },
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' })
  }).then((response) => {

    let responseData = null;

    if (response.status === 200) {

      responseData = FORMAT.SUMMARY_BEAUTIFY(response.data);

    }

    return responseData;

  }).catch((error) => {

    console.error('Error:', error);

  });

  const { isLoading, data: summary } = useQuery(
    ['summary-account', filter],
    () => fetchAccountSummary()
  );

  useEffect(() => {

    if (!isLoading && JSON.stringify(currentUser) !== JSON.stringify(viewAs)) {

      if (summary) {

        const lastTradeDay = dayjs(summary.lastTradeDay).add(1, 'day');
        changeFilter('dateRange', [dayjs(summary.firstTradeDay), lastTradeDay]);

      } else {

        changeFilter('dateRange', null);

      }

      changeFilter('tags', []);
      changeFilter('status', ['open', 'closed']);
      changeFilter('side', ['Long', 'Short']);
      changeFilter('assetType', ['Stock', 'Option', 'Future', 'Cash']);

      setCurrentUser(viewAs);

    }

  }, [summary, isLoading]);

  const tableOneData = summary ? [
    {
      label: 'Total gain/loss',
      value: summary.netPnL,
      description: dashboard.totalGainLoss
    },
    {
      label: 'Average gain/loss per trade',
      value: summary.avgPnL,
      description: dashboard.averageGainLossPerTrade
    },
    {
      label: 'Average daily gain/loss',
      value: summary.avgPnLPerDay
    },
    {
      label: 'Total number of trades',
      value: summary.totalTrades,
      description: dashboard.totalNumberOfTrades
    },
    {
      label: 'Number of long trades',
      value: summary.totalLong
    },
    {
      label: 'Number of short trades',
      value: summary.totalShort
    },
    {
      label: 'Number of open trades',
      value: summary.totalOpenTrades
    },
    {
      label: 'Number of closed trades',
      value: summary.totalClosedTrades
    }
  ] : [];

  const tableTwoData = summary ? [
    {
      label: 'Number of winning trades',
      value: summary.totalWins,
      description: dashboard.numberOfWinningTrades
    },
    {
      label: 'Number of losing trades',
      value: summary.totalLosses,
      description: dashboard.numberOfLosingTrades
    },
    {
      label: 'Max consecutive wins',
      value: summary.maxConsecutiveWins,
      description: dashboard.maxConsecutiveWins
    },
    {
      label: 'Max consecutive losses',
      value: summary.maxConsecutiveLosses,
      description: dashboard.maxConsecutiveLosses
    },
    {
      label: 'Average hold time (winning trades)',
      value: summary.avgTradeDurationPerWin,
      description: dashboard.averageHoldTimeWinTrades
    },
    {
      label: 'Average hold time (losing trades)',
      value: summary.avgTradeDurationPerLoss,
      description: dashboard.averageHoldTimeLossTrades
    },
    {
      label: 'Longest hold time (winning trades)',
      value: summary.longestTradeDurationPerWin
    },
    {
      label: 'Longest hold time (losing trades)',
      value: summary.longestTradeDurationPerLoss
    }
  ] : [];

  const tableThreeData = summary ? [
    {
      label: 'Most traded symbol',
      value: summary.maxTradesPerSymbol.symbol
    },
    {
      label: 'Most profitable symbol',
      value: `${summary.mostProfitableByPnL.symbol} (${summary.mostProfitableByPnL.PnL})`
    },
    {
      label: 'Least profitable symbol',
      value: `${summary.leastProfitableByPnL.symbol} (${summary.leastProfitableByPnL.PnL})`
    },
    {
      label: 'Win/Loss Ratio',
      value: summary.winLossRatio,
      description: dashboard.winLossRatio
    },
    {
      label: 'First trading day',
      value: summary.firstTradeDay
    },
    {
      label: 'Last trading day',
      value: summary.lastTradeDay
    },
    {
      label: 'Total commissions & fees',
      value: summary.totalCommissions,
      description: dashboard.totalCommissionsFees
    }
  ] : [];

  if (isLoading) return <></>;

  if (!summary) {

    return (
        <Space direction='vertical' align='center' className='no-data' wrap>
            <Title>No data available.</Title>
            <Text>Select different filters or upload trade logs by using the <strong>Import Trades</strong> button.</Text>
        </Space>
    );

  }

  return (
      <Space className='stretch-space' direction='vertical' wrap>
          <StatsCards {...summary} />
          <Row>
              <Col span={8}>
                  <DashboardSummaryTable data={tableOneData} />
              </Col>
              <Col span={8}>
                  <DashboardSummaryTable data={tableTwoData} />
              </Col>
              <Col span={8}>
                  <DashboardSummaryTable data={tableThreeData} />
              </Col>
          </Row>

      </Space>
  );

}
